import {
  FunctionComponent,
  PropsWithChildren,
  useMemo,
  // eslint-disable-next-line prettier/prettier
  useReducer,
} from "react";

import DrawerContext from "../contexts/BreadcrumbContext";
import reducer from "../reducers/breadcrumbReducer";
import initialState from "../states/breadcrumbState";
import { Context } from "../types/Breadcrumb";

const BreadcrumbProvider: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const value: Context = useMemo(
    () => ({ state, dispatch }),
    [state, dispatch]
  );

  return (
    <DrawerContext.Provider value={value}>{children}</DrawerContext.Provider>
  );
};

export default BreadcrumbProvider;
