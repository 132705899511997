import { Dispatch } from "react";
// import initialState from "../states/propertyState";

import { ActionType, State } from "../types/Property";

export const setProperty = (dispatch: Dispatch<any>, payload: State) => {
  return dispatch({
    type: ActionType.SET_INVESTOR_PROPERTY,
    payload: { property: payload.property },
  });
};

export const removeProperty = (dispatch: Dispatch<any>) => {
  return dispatch({
    type: ActionType.REMOVE_INVESTOR_PROPERTY,
    payload: { property: undefined },
  });
};
