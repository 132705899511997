import { createContext } from "react";

import initialState from "../states/propertyState";
import { Context } from "../types/Property";

const contextInitialState: Context = {
  state: initialState,
  dispatch: () => {},
};

const PropertyContext = createContext<Context>(contextInitialState);

export default PropertyContext;
