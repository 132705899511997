import { Dispatch } from "react";

export interface State {
  breadcrumb: string;
}

export interface Action {
  type: string;
  payload: string;
}

export interface Context {
  state: State;
  dispatch: Dispatch<any>;
}

export enum ActionType {
  SET_BREADCRUMB = "SET_BREADCRUMB",
}
