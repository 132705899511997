import { Dispatch } from "react";

import { ActionType, State } from "../types/Authenticate";

export const setIsAuthenticate = (dispatch: Dispatch<any>, payload: State) => {
  return dispatch({
    type: ActionType.SET_IS_AUTHENTICATE,
    payload,
  });
};

export const removeIsAuthenticate = (dispatch: Dispatch<any>) => {
  return dispatch({
    type: ActionType.REMOVE_IS_AUTHENTICATE,
    payload: { authenticate: false },
  });
};
