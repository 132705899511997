/* eslint-disable prettier/prettier */
import { lazy } from "react";
import TestPage from "../pages/test-page/TestPage";
import Welcome from "../pages/welcome/Welcome";
import Route from "../types/Route";

const SignInWithEmailPassword = lazy(
  () => import("../pages/email-password-signin/SignInWithEmailPassword")
);
const SignInViaEmail = lazy(
  () => import("../pages/password-less-signin/SignInViaEmail")
);
const SentEmail = lazy(() => import("../pages/send-link-to-email/SentEmail"));
const ExpiredLink = lazy(
  () => import("../pages/email-link-expired/ExpiredLink")
);
const AuthCallback = lazy(() => import("../pages/auth-callback/AuthCallback"));
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
const SignUp = lazy(() => import("../pages/password-less-signup/SignUp"));
const EligibilityCheck = lazy(
  () => import("../pages/eligibility-check/EligibilityCheck")
);
const Dashboard = lazy(() => import("../pages/dashboard/Dashboard"));
const Onboarding = lazy(() => import("../pages/onboarding/Onboarding"));
const PropertyOnboarding = lazy(
  () => import("../pages/property-onboarding/PropertyOnboarding")
);
const ProcessingApplication = lazy(
  () => import("../pages/onboarding/ProcessingApplication")
);
const InvestmentManagement = lazy(
  () => import("../pages/investment-management/InvestmentManagement")
);
const OnboardNewProperty = lazy(
  () => import("../pages/onboard-new-property/OnboardNewProperty")
);
const Performance = lazy(() => import("../pages/performance/Performance"));
const Profile = lazy(() => import("../pages/profile/Profile"));
const Accounts = lazy(() => import("../pages/accounts/Accounts"));
const Notifications = lazy(
  () => import("../pages/notifications/Notifications")
);
const TermsAndPolicies = lazy(() => import("../pages/more/TermsAndPolicies"));
const GetHelp = lazy(() => import("../pages/help/Help"));
const Security = lazy(() => import("../pages/security/Security"));
const EnableMultiFactorAuthentication = lazy(
  () => import("../pages/security-enable-multifactor/EnableMultiFactorSecurity")
);
const EnableOrDownloadMultifactorAuthentication = lazy(
  () => import("../pages/security-download-or-enable-mfa/EnableOrDownloadMFA")
);
const ChangePassword = lazy(
  () => import("../pages/security-change-password/ChangePassword")
);

const routes: Route[] = [
  {
    id: "724cf4ea-f069-11ec-8ea0-0242ac12000a",
    name: "signin",
    path: "/signin",
    icon: null,
    exact: true,
    protected: false,
    component: SignInWithEmailPassword,
  },
  {
    id: "6ac13338-854e-4840-9840-8d107c416878",
    name: "SignInViaEmail",
    path: "/email-signin",
    icon: null,
    exact: true,
    protected: false,
    component: SignInViaEmail,
  },
  {
    id: "ee7f74d7-56af-4e9e-93cc-baa1931b9b29",
    name: "SentEmail",
    path: "/confirm-email",
    icon: null,
    exact: true,
    protected: false,
    component: SentEmail,
  },
  {
    id: "ee7f74d7-56af-4e9e-93cc-baa1931b4391",
    name: "ExpiredLink",
    path: "/link-expired",
    icon: null,
    exact: true,
    protected: false,
    component: ExpiredLink,
  },
  {
    id: "c06b63e4-6244-4044-abfe-3855cc23a280",
    name: "auth-callback",
    path: "/auth/callback",
    icon: null,
    exact: true,
    protected: false,
    component: AuthCallback,
  },
  // {
  //   id: "724cf4ea-f069-11ec-8ea0-0242ac13111b",
  //   name: "signup",
  //   path: "/",
  //   icon: null,
  //   exact: true,
  //   protected: false,
  //   component: SignUp,
  // },
  {
    id: "724cf4ea-f069-11ec-8ea0-0242ac13111b",
    name: "signin",
    path: "/",
    icon: null,
    exact: true,
    protected: false,
    component: SignInViaEmail,
  },
  {
    id: "724cf4ea-f069-11ec-8ea0-0242ac13222c",
    name: "eligibility-check",
    path: "/eligibility-check",
    icon: null,
    exact: true,
    protected: false,
    component: EligibilityCheck,
  },
  {
    id: "724cf4ea-f069-11ec-8ea0-0242ac43922d",
    name: "dashboard",
    path: "/dashboard",
    icon: null,
    exact: true,
    protected: true,
    component: Dashboard,
  },
  {
    id: "724cf4ea-f069-11ec-8ea0-0242ac43922l",
    name: "Onboarding",
    path: "/onboarding",
    icon: null,
    exact: true,
    protected: false,
    public: true,
    component: Onboarding,
  },
  {
    id: "724cf4ea-f069-11ec-8ea0-0242ac43956i",
    name: "PropertyOnboarding",
    path: "/property-onboarding",
    icon: null,
    exact: true,
    protected: true,
    component: PropertyOnboarding,
  },
  {
    id: "724cf4ea-f069-11ec-8ea0-0242ac43922e",
    name: "InvestmentManagement",
    path: "/investment-management",
    icon: null,
    exact: true,
    protected: true,
    component: InvestmentManagement,
  },
  {
    id: "724cf4ea-f069-11ec-8ea0-0242ac43922e",
    name: "OnboardNewProperty",
    path: "/property-onboarding/new",
    icon: null,
    exact: true,
    protected: true,
    component: OnboardNewProperty,
  },
  {
    id: "724cf4ea-f069-11ec-8ea0-0242ac43922f",
    name: "Performance",
    path: "/performance",
    icon: null,
    exact: true,
    protected: true,
    component: Performance,
  },
  {
    id: "724cf4ea-f069-11ec-8ea0-0242ac43934f",
    name: "Welcome",
    path: "/welcome",
    icon: null,
    exact: true,
    protected: false,
    component: Welcome,
  },
  {
    id: "724cf4ea-f069-11ec-8ea0-0242ac43922m",
    name: "ProcessingApplication",
    path: "/onboarding/processing",
    icon: null,
    exact: true,
    protected: false,
    component: ProcessingApplication,
    public: true,
  },
  {
    id: "724cf4ea-f069-11ec-8ea0-0242ac43922g",
    name: "Profile",
    path: "/profile",
    icon: null,
    exact: true,
    protected: true,
    component: Profile,
  },
  {
    id: "724cf4ea-f069-11ec-8ea0-0242ac43922h",
    name: "Accounts",
    path: "/accounts",
    icon: null,
    exact: true,
    protected: true,
    component: Accounts,
  },
  {
    id: "724cf4ea-f069-11ec-8ea0-0242ac43922i",
    name: "Notifications",
    path: "/notifications",
    icon: null,
    exact: true,
    protected: true,
    component: Notifications,
  },
  {
    id: "724cf4ea-f069-11ec-8ea0-0242ac43922op",
    name: "Security",
    path: "/security",
    icon: null,
    exact: true,
    protected: true,
    component: Security,
  },
  {
    id: "724cf4ea-f069-11ec-8ea0-02340943922dS",
    name: "EnableOrDownloadMultifactorAuthentication",
    path: "/security/multi-factor-authentication-options",
    icon: null,
    exact: true,
    protected: true,
    component: EnableOrDownloadMultifactorAuthentication,
  },
  {
    id: "724cf4ea-f069-11ec-8ea0-0242ac43922os",
    name: "EnableMultiFactorAuthentication",
    path: "/security/multi-factor-authentication",
    icon: null,
    exact: true,
    protected: true,
    component: EnableMultiFactorAuthentication,
  },
  {
    id: "724cf4ea-f069-11ec-8ea0-0242ac439234os",
    name: "ChangePassword",
    path: "/security/change-password",
    icon: null,
    exact: true,
    protected: true,
    component: ChangePassword,
  },
  {
    id: "724cf4ea-f069-11ec-8ea0-0242ac43922j",
    name: "TermsAndPolicies",
    path: "/more",
    icon: null,
    exact: true,
    protected: false,
    public: true,
    component: TermsAndPolicies,
  },
  {
    id: "724cf4ea-f069-11ec-8ea0-0242ac43922k",
    name: "GetHelp",
    path: "/help",
    icon: null,
    exact: true,
    protected: false,
    public: true,
    component: GetHelp,
  },
  {
    id: "724cf4ea-f069-11ec-8ea0-0242ac43933f",
    name: "TestPage",
    path: "/test-page",
    icon: null,
    exact: true,
    protected: false,
    component: TestPage,
  },
];

export default routes;
