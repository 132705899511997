import {
  FunctionComponent,
  PropsWithChildren,
  useMemo,
  // eslint-disable-next-line prettier/prettier
  useReducer,
} from "react";

import PropertyContext from "../contexts/PropertyContext";
import reducer from "../reducers/propertyReducer";
import initialState from "../states/propertyState";
import { Context } from "../types/Property";

const PropertyProvider: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const value: Context = useMemo(
    () => ({ state, dispatch }),
    [state, dispatch]
  );

  return (
    <PropertyContext.Provider value={value}>
      {children}
    </PropertyContext.Provider>
  );
};

export default PropertyProvider;
