import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { QueryFunctionContext, useQuery } from "@tanstack/react-query";
import { API, Auth, graphqlOperation } from "aws-amplify";
import { useEffect, useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Helmet } from "react-helmet-async";
import { getInvestorOnboardingAccount } from "../../apis/eligibility-check";
import { onUpdateInvestorOnboardingProperty as onUpdateInvestorOnboarding } from "../../graphql/subscriptions";
import useAuthenticate from "../../hooks/useAuthenticate";
import { OnboardingStatus } from "../../models";
import { setIsAuthenticate } from "../../shared";
import isEmpty from "../../utils/isEmpty";

const Welcome = () => {
  const [userDetails, setUserDetails] = useState(() =>
    JSON.parse(
      localStorage.getItem("user") ||
        localStorage.getItem("user-details") ||
        "{}"
    )
  );
  const [userId, setUserId] = useState(() => localStorage.getItem("userSub"));
  const [email, setEmail] = useState(
    () => JSON.parse(localStorage.getItem("user-details") || "{}")?.email || ""
  );
  const [fileInviteUrl, setFileInviteUrl] = useState("");
  const [waitingTimeOfFileInviteUrl, setWaitingTimeOfFileInviteUrl] =
    useState(120);
  const [eligibilityUserId] = useState(() => localStorage.getItem("userSub"));
  const [time, setTime] = useState(45);

  const navigateTo = useNavigate();
  const { dispatch: authDispatch } = useAuthenticate();
  const reRunApiAfterMilliseconds = 5000;

  const { refetch } = useQuery(
    ["investor-onboarding/check-status", email || userDetails?.username],
    ({ queryKey }: QueryFunctionContext) =>
      getInvestorOnboardingAccount(queryKey[1] as string),
    { enabled: false }
  );

  const checkOnboardingStatusAppendFileInviteURLorRedirect = async (
    callback?: (url: string) => void,
    data?: { [key: string]: string | number }
  ) => {
    try {
      const response = data || (await refetch());
      let investorOnboardingAccount = null;

      if (
        response.isSuccess &&
        response.data?.data?.listInvestorOnboardingProperties?.items?.length
      ) {
        investorOnboardingAccount =
          response.data?.data?.listInvestorOnboardingProperties.items[0];
      } else {
        investorOnboardingAccount = data;
      }

      if (!isEmpty(investorOnboardingAccount)) {
        if (
          investorOnboardingAccount?.status === OnboardingStatus.ACCOUNT_CREATED
        ) {
          navigateTo(`/eligibility-check?email=${userDetails?.email}`, {
            replace: true,
          });
        }

        // TODO: change the status to UPLOAD_DOCUMENT when API issue resolved
        if (
          investorOnboardingAccount?.fileInviteUrl &&
          investorOnboardingAccount?.status ===
            OnboardingStatus.UPLOAD_DOCUMENTS
        ) {
          if (callback && investorOnboardingAccount?.fileInviteUrl) {
            callback(investorOnboardingAccount?.fileInviteUrl);
          }
          // navigateTo("/onboarding", { replace: true });
          // Navigating user to the dashboard and give permission
          localStorage.setItem("eligibility-check-status", "true");
          localStorage.setItem(
            "eligibility-user-id",
            eligibilityUserId as string
          );
          localStorage.setItem("has-access-of-dashboard", "true");
          localStorage.setItem("is-authenticated", "true");
          setIsAuthenticate(authDispatch, { authenticated: true });

          navigateTo("/dashboard", { replace: true });
        }
      } else {
        // const timestamp = Date.now();
        // navigateTo(`/eligibility-check?email=${email}&timestamp=${timestamp}`, {
        //   replace: true,
        // });
        // eslint-disable-next-line no-useless-return
        return;
      }
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    if (!userId) {
      Auth.currentUserInfo()
        .then((response) => {
          if (response && response?.attributes) {
            setUserId(response.attributes?.sub);
          } else {
            navigateTo("/");
          }
        })
        .catch((error) => {
          console.log({ error });
          throw new Error("User does not authenticate");
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!userId || !email) {
      Auth.currentUserInfo()
        .then((response) => {
          if (response && response?.attributes) {
            setUserId(response.attributes?.sub);
            setEmail(response.attributes?.email);
          } else {
            navigateTo("/");
          }
        })
        .catch((error) => {
          console.log({ error });
          throw new Error("User does not authenticate");
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const subscribeOnUpdateInvestorOnboardingStatus = API.graphql(
      graphqlOperation(onUpdateInvestorOnboarding, {
        variables: {
          owner: userId,
        },
      })
      // @ts-ignore
    ).subscribe({
      // @ts-ignore
      // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
      next: ({ provider, value }) => {
        const data = value.data.onUpdateInvestorOnboardingProperty;
        checkOnboardingStatusAppendFileInviteURLorRedirect(
          setFileInviteUrl,
          data
        );
      },
      // @ts-ignore
      error: (error) => console.log({ error }),
    });

    return () => subscribeOnUpdateInvestorOnboardingStatus.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  useEffect(() => {
    const timeLeft = setInterval(
      () =>
        setWaitingTimeOfFileInviteUrl((prevTime: number) => {
          checkOnboardingStatusAppendFileInviteURLorRedirect(setFileInviteUrl);
          return prevTime - 5;
        }),
      reRunApiAfterMilliseconds
    );

    if (fileInviteUrl || waitingTimeOfFileInviteUrl === 0) {
      clearInterval(timeLeft);
    }

    return () => {
      clearInterval(timeLeft);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileInviteUrl, waitingTimeOfFileInviteUrl]);

  useEffect(() => {
    const timeLeft = setInterval(
      () => setTime((prevTime) => prevTime - 1),
      1000
    );

    if (time === 0) {
      clearInterval(timeLeft);
    }

    return () => {
      clearInterval(timeLeft);
    };
  }, [time]);

  useLayoutEffect(() => {
    if (isEmpty(userDetails)) {
      Auth.currentAuthenticatedUser()
        .then((userPool) => {
          if (userPool && userPool?.attributes) {
            setUserDetails(userPool?.attributes);
          }
        })
        .catch((error) => {
          console.log({ error });
          navigateTo("/");
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    if (email) {
      checkOnboardingStatusAppendFileInviteURLorRedirect(setFileInviteUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  return (
    <>
      <Helmet>
        <title>Property Onboarding In Progress | Our Leg Up Dashboard</title>
      </Helmet>

      <Grid
        container
        py={5}
        direction={{ xs: "column-reverse", sm: "column-reverse", md: "row" }}
        minHeight="100vh"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} sm={12} md={10} mx="auto">
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            gap={1.3}
            px={4}
          >
            {!fileInviteUrl && (
              <CheckCircleIcon sx={{ fontSize: 60 }} color="success" />
            )}

            <Typography paragraph fontWeight="bold" variant="h5">
              Congratulations!
            </Typography>

            <Typography paragraph fontWeight="bold" variant="h6">
              Successfully completed eligibility check process
            </Typography>

            <Typography paragraph mb={2}>
              Please wait while we&apos;re creating you a dashboard
            </Typography>

            <Typography paragraph mb={2} variant="caption">
              This will take around {time} seconds on the form...
            </Typography>

            {!fileInviteUrl && <CircularProgress />}

            <Typography
              paragraph
              fontWeight="bold"
              mt={2}
              sx={{ color: (theme) => theme.palette.primary.main }}
            >
              We&apos;ll be sending you an email when the process gets
              completed!
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Welcome;
