import { Dispatch } from "react";

export interface State {
  user: {
    email: string;
    email_verified?: boolean;
    family_name: string;
    given_name: string;
    name: string;
    phone_number: string;
    phone_number_verified?: boolean;
    sub?: string;
  };
}

export interface Action {
  type: string;
  payload: State;
}

export interface Context {
  state: State;
  dispatch: Dispatch<any>;
}

export enum ActionType {
  SET_USER_DETAILS = "SET_USER_DETAILS",
  REMOVE_USER_DETAILS = "REMOVE_USER_DETAILS",
}
