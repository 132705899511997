import { Box, Typography } from "@mui/material";
import { Auth } from "aws-amplify";
import axios from "axios";
import { useEffect } from "react";
import getRandomString from "../../utils/getRandomString";

const TestPage = () => {
  // const [status, setStatus] = useState("");
  // const [userDetails] = useState(() =>
  //   JSON.parse(
  //     localStorage.getItem("user") ||
  //       localStorage.getItem("user-details") ||
  //       "{}"
  //   )
  // );

  // const statuses = [
  //   { label: "Account created", value: "ACCOUNT_CREATED" },
  //   { label: "Personal information", value: "PERSONAL_INFORMATION" },
  //   { label: "Intro call", value: "INTRO_CALL" },
  //   { label: "Complete registration", value: "COMPLETE_REGISTRATION" },
  //   { label: "Intro call scheduled", value: "INTRO_CALL_SCHEDULED" },
  //   { label: "Upload documents", value: "UPLOAD_DOCUMENTS" },
  //   { label: "Review of files", value: "REVIEW_OF_FILES" },
  //   {
  //     label: "Second rank mortgage process",
  //     value: "SECOND_RANK_MORTGAGE_PROCESS",
  //   },
  //   { label: "Land title registration", value: "LAND_TITLE_REGISTRATION" },
  //   { label: "Closed lost", value: "CLOSED_LOST" },
  //   { label: "Closed won", value: "CLOSED_WON" },
  // ];

  // const handleChangeStatusValue = (e: { target: { value: any } }) =>
  //   setStatus(e.target.value);

  // const handleChangeStatusIntoAPI = async () => {
  //   try {
  //     const data = {
  //       email: userDetails?.email,
  //       status,
  //     };

  //     const response = await API.graphql(
  //       graphqlOperation(updateInvestorOnboarding, { input: data })
  //     );

  //     console.log({ response });

  //     return response;
  //   } catch (error) {
  //     console.log({ error });
  //     throw new Error(
  //       "Could not create investor account right now, Try again later"
  //     );
  //   }
  // };

  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const connectToCognitoUserPool = async () => {
    try {
      const response = await axios.post(
        "https://cognito-idp.ap-southeast-2.amazonaws.com/",
        {
          ClientId: "1t95oqkrc078ng5pdvv7qhrert",
          Username: "shakil+test3@ourlegup.com",
          Password:
            "c5a3d0d5cff917c4cd59b8fbd73d3c93860f7fff9a72d803813623b11c7d",
          UserAttributes: [
            { Name: "name", Value: "Shakil Ahmed" },
            { Name: "given_name", Value: "Shakil" },
            { Name: "family_name", Value: "Ahmed" },
            { Name: "phone_number", Value: "+610417913452" },
          ],
          ValidationData: null,
          AuthFlow: "USER_SRP_AUTH",
          AuthParameters: {
            USERNAME: "shakil+test4@ourlegup.com",
            SRP_A:
              "52f6982f80f4ba2b7668e3e88d720a45ae2657963a37bb26f548608e6f4726f2f7566ff99e9521b975a2590ee54278caf4bc7cc9432f2809066bfcfaf9e66db3c27daa4e36d6ea27bc12df68d10eb5a810df2897ec71b6527e0b3ca03f2171db134e1a0f8bed43efa8598998b9bbd449c5e596980ffdb86e75db204b6ef3191345b4e1424aa40c569437551c8c48f226d83aa20218245786ea177c088151401c95ad5f24585882e3e2960d2759780c384b35ec08aa6557a029c39c4f43752106a1a882955e2f8b03c5bcd1dd599502473a8763a0296739950782d523e42a1810e0611e94ba87b5989d5b45f15fd891647386e906407ad49f7e34fb1ccafd3678c773df224d35a037342c411ae5c7de13416784e43c22e0b9de8feb13d140f43f48227ad21c030af624871ec5da1fec433087b3c3f17bf6b54ed7a94fd9ab62c19d4909a20af27f47694e550b1f139ceb3b88177ef5fc2c38d358365fbdb27c8878a22e2ea5799d1fb2bea6233744810ca18b00f07b718be160e9fb1b56e87205",
          },
          ChallengeName: "PASSWORD_VERIFIER",
          ChallengeResponses: {
            USERNAME: "b3904325-1959-4da0-998a-633380ef17d2",
            PASSWORD_CLAIM_SECRET_BLOCK:
              "JdeJK8Nw923WNG1ZzsjDJEQ8WyJWJg28MzThalNH2CfR9NwKJxPGxxHtZFWVwyQcOqnIYPDGhxzufP3MZbCU/+NUzmydMn/yIk/hrLSBOoFw2fcwxZ1Uma6uh9iXVIygS4fUKPb8XI+6mgaADnsn1+a5UgAPYunvSUSrt8t3Tyb0rrn8k4yrZX2kHlKtPDTvU9r29MndgM07KZSpkRfzSbw5Hr3DgMXnUhsmf1hKnxbmfdyPMNixxKU0Io7MdFrGkilZ7x8dag7pjBdpGxj13AiVGGAm4m+swhxPvi568KnE++2PnZGipqwpODQ2F6jWCAXkzdfbPfUIEWBGUHubBBi0e+zcXG7cxVlKwHf5b8uLsokGGnIx0FIcZT+Hq6jN5VeJK2QX1wBh3N+OC+72t4ombD4kMtXZ3JKC0f6tko1lXUzBM/c8TLvOlOU9vMYPEnNiltRxx8La5RuzOiKu6i2EfyCn/XCef1fwGyLfTwe7APgJ2mn1xMbclvkvhHD7Zzq5ItEZ6maO3SgGO6H8Ou/tRpnAnQaAAqtI9DHuHdIv/WQkYN4PYtbXw1Ms7EK+RDw4aAK3K5waJzpMe5hvwINOZ09IdhD5Q9BIolO+8ircuyiOxvh+w978MO/BBRB+vXdlJTNM3oIcOJTPclmLUMa5NoDj4e2+qRVnbNjy+6RWicBwgTNITE12Uf5eum1qrpcPPwurUSkI/dW4X5cvSj7ulpPW0HP2MWbQ1bXau26fB9eYPZORPo4BiK+txtFRFS0OcxvNs4XmyAyN3hvwkjE4M2ODLNEddIeWMNKJa3C5RxRe16SN54ZXQoFoKREOAr6YGGWVDc6R29LVr1Ytox6TfKdV+pzDAK3tad6bjkhIuXczTuoDwNCEz0xOI6cXOpdyjjj5qfUPJFejAegMadnUtOek/BKiuDKh8bTsW1nlXA+Oz0rZUlthTANx9s/7/zymLXlQPwhcbyFwLFMjpZ6HlSgcpGwigfA903LMO4KE48SVx7QIvtZe7yHQwCHkwmJzKk3aDpzgwYE5p4+/Hi+GpfmjQ5e4qkSrGVhfMX7d0TQFWOyA3Os2y8atHCc7jVNWqlWXzkKyJabj1Yo/ISAFPC6/SN7kVCG68zJ8LNK2w4PCU+cCJz2+neTRVIWb+wbZmwzzqAyuQPNFZyxydwPuMan0eQlretqBuxBvrv8CWXZo3sUsFC+s7nowvFFvFhxkZGBnQTwU8w7NutzrrA085Kb3yCev7EAW6l6oOgiJjcGG+9vOJ1DjXhWPaIeMUCDn928vIPVGmgwZC/WhLfQwgkvEMMbofVJoaRdKEBht6kHSrmjk37SIHMq8LvWKp/LPDmnvSGf4h+ZYJJtANjSJ9P4M8ZPypNNFbz4bHWbMidPCQWwOHul+vTnFhnKdqb+PPJEDz094GZwmMNTcN4ZrN6uO2eahFhCqwsEHc3AUzsdYTg52HSF/fk2JY9utSBnCFPn1s7fp/jy6kwVapKwfrZqjnm1phUlt6u2SYtyMWgiSJJ0NiA+tKLh+Rjg9yy1VgrynDPLIDsZ4rDT2N8vtLyaXFksOqV0MIW61AGDk8URR+niylSOafIbRnqhvBfoo28hwd6SAZFlX0ix+Sd1321bUh1g+KCA4QnoDYqt5MByKVbmkGO93iAAMfolkgw46CHCVJtpwbD2tvKr4XVoXXX+0A8S4fnPogs0uc9zTHD4wZMElM8khAA==",
            TIMESTAMP: "Thu Feb 2 08:00:01 UTC 2023",
            PASSWORD_CLAIM_SIGNATURE:
              "kTw5TENGpKyJT+/3v28NMA/pySpc4K6tWVBfn4+bue8=",
          },
          ClientMetadata: {},
          IdentityPoolId: "ap-southeast-2:34bb42df-af2f-43bb-86f2-a1d777de33c8",
          Logins: {
            "cognito-idp.ap-southeast-2.amazonaws.com/ap-southeast-2_sTq9xNMfC":
              "eyJraWQiOiJkUW9mZ3AyR0xsS1BtKzRpcE5IclJvN2xjcHBNbmpZQXV6UE82enloWjJvPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiJiMzkwNDMyNS0xOTU5LTRkYTAtOTk4YS02MzMzODBlZjE3ZDIiLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAuYXAtc291dGhlYXN0LTIuYW1hem9uYXdzLmNvbVwvYXAtc291dGhlYXN0LTJfc1RxOXhOTWZDIiwiY29nbml0bzp1c2VybmFtZSI6ImIzOTA0MzI1LTE5NTktNGRhMC05OThhLTYzMzM4MGVmMTdkMiIsImdpdmVuX25hbWUiOiJTaGFraWwiLCJvcmlnaW5fanRpIjoiNzhiNDc2ZDAtNDkzNC00ODkyLTk2ODEtYzdkZGY3YTNmZTBmIiwiYXVkIjoiMXQ5NW9xa3JjMDc4bmc1cGR2djdxaHJlcnQiLCJldmVudF9pZCI6IjBhZmFmYmU4LTZkOGYtNGYzNS05OGRlLTk5MzQ4ZjcxOGZiMyIsInRva2VuX3VzZSI6ImlkIiwiYXV0aF90aW1lIjoxNjc1MzI0ODAyLCJuYW1lIjoiU2hha2lsIEFobWVkIiwicGhvbmVfbnVtYmVyIjoiKzYxMDQxMjM0ODYzMiIsImV4cCI6MTY3NTMyODQwMiwiaWF0IjoxNjc1MzI0ODAyLCJmYW1pbHlfbmFtZSI6IkFobWVkIiwianRpIjoiNDg4ZmFhNjItNTQwNy00M2VhLTllNjYtYTZmNmUyNzI3ODdiIiwiZW1haWwiOiJzaGFraWwrdGVzdDRAb3VybGVndXAuY29tIn0.LcVNGQiKURZi7vNaovVgKQVm4fB2rBlfzJwo0DhR0tJvFgsxfsi3WmMAZnvDegbx2tiZg2XhQH5j3yJG0oUGuONPs3zKdQbmSqEI7e5Pb3WLhywwOcpX-2s6vRN2OhiptM6H6awqG_MtKkY8y6upl9eUdm339zvift-cnemHHlOZnYIKGSXQ5A458q0KYVZl6ifKNoFowt5ttHTgVOS8JM1HIKd6OEMYuzRM0AOy8q1oE2WBLZ9TWjdjiZiTJnv58dgGm0MHf_lRl9qNs3JN2Zur-_ZC3rprbTq1XTsWw9mBKVHD96y9s4mYyTwU0b0jD8jvIbeqlszYRt9ULxKNsA",
          },
        },
        {
          headers: {},
        }
      );
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  const connectToUserCognitoUsingAmplify = async () => {
    try {
      const params = {
        username: "shakil+test5@ourlegup.com",
        password: getRandomString(30),
        attributes: {
          name: "Shakil Ahmed",
          given_name: "Shakil",
          family_name: "Ahmed",
          phone_number: "+610412345753",
        },
        autoSignIn: {
          enabled: true,
        },
      };

      const user = await Auth.signUp(params);

      console.log({ user });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // connectToCognitoUserPool();
    connectToUserCognitoUsingAmplify();
  }, []);

  return (
    // <Box
    //   display="flex"
    //   flexDirection="column"
    //   justifyContent="center"
    //   alignItems="center"
    //   padding={5}
    //   mx="auto"
    //   // fontSize={30}
    // >
    //   <select value={status} onChange={handleChangeStatusValue}>
    //     <option>Select option to update status</option>
    //     {/* eslint-disable-next-line @typescript-eslint/no-shadow */}
    //     {statuses.map((status) => (
    //       <option key={status.value} value={status.value}>
    //         {status.label}
    //       </option>
    //     ))}
    //   </select>

    //   <h3>Selected status - {status}</h3>

    //   <Button
    //     variant="contained"
    //     sx={{ mt: 1 }}
    //     onClick={handleChangeStatusIntoAPI}
    //   >
    //     Change Status
    //   </Button>
    // </Box>

    <Box>
      <Typography>Hello from test page</Typography>

      {/* <Grid item xs={12} sm={12} md={12} pb={3} pt={12}>
        <Box display="flex" flexDirection="column" gap={3}>
          <Typography variant="h4" fontWeight="bold">
            Verify your account
          </Typography>

          <Typography paragraph maxWidth={600}>
            You can verify your profile by scanning this QR code with the
            Authenticator app in your mobile phone and enter the 6-digit
            verification code.
          </Typography>

          <Box>
            <Box
              sx={{
                display: "inline-block",
                p: 2,
                borderWidth: 1,
                borderColor: (theme) => theme.palette.secondary.light,
                background: (theme) => theme.palette.secondary.light,
                borderStyle: "solid",
              }}
            >
              <img src={Qrcode} alt="Varify QR Code" />
            </Box>
          </Box>

          <Formik
            initialValues={{ code: undefined }}
            validationSchema={{}}
            onSubmit={handleSubmitQRCode}
          >
            <fieldset style={{ border: "none", padding: 0 }}>
              <Form>
                <TextInputField
                  fullWidth
                  boxStyle={{ maxWidth: 340 }}
                  isRequired
                  type="number"
                  name="qrcode"
                  label="Enter code"
                />
              </Form>
            </fieldset>
          </Formik>
        </Box>
      </Grid> */}
    </Box>
  );
};

export default TestPage;
