import { Menu, NotificationsRounded } from "@mui/icons-material";
import {
  AppBar,
  Badge,
  Box,
  CssBaseline,
  IconButton,
  // eslint-disable-next-line prettier/prettier
  Toolbar,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import MockDate from "../../components/molecules/MockDate";
import { drawerWidth } from "../../constants/layout";
import useDrawer from "../../hooks/useDrawer";
import { closeSidebarDrawer, openSidebarDrawer } from "../../shared";
import Breadcrumb from "./Breadcrumb";
import UserProfileMenu from "./UserProfileMenu";

const Header = () => {
  const { state: drawer, dispatch } = useDrawer();
  const navigateTo = useNavigate();

  const handleDrawerToggle = () => {
    if (drawer.open) {
      closeSidebarDrawer(dispatch);
    } else {
      openSidebarDrawer(dispatch);
    }
  };

  const handleNotification = () => navigateTo("/notifications");

  return (
    <>
      <CssBaseline />
      <AppBar
        position="fixed"
        color="secondary"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          py: 1.5,
          boxShadow: "none",
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <IconButton
            color="secondary"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 1, display: { sm: "none" } }}
          >
            <Menu color="primary" />
          </IconButton>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Breadcrumb />

            <Box display="flex" justifyContent="center" alignItems="center">
              <MockDate />

              <IconButton onClick={handleNotification}>
                <Badge color="warning" variant="dot">
                  <NotificationsRounded color="primary" />
                </Badge>
              </IconButton>

              <UserProfileMenu />
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Header;
