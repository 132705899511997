import { useContext } from "react";
import { AuthenticateContext } from "../shared";

const useAuthenticate = () => {
  const context = useContext(AuthenticateContext);

  if (context === undefined) {
    throw new Error("useAuthenticate must be used within a BreadcrumbProvider");
  }

  return context;
};

export default useAuthenticate;
