import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import useMockDate from "../../hooks/useMockDate";

const MockDate = () => {
  const { mockDate, setMockDate } = useMockDate();

  const handleDateChange = (value: Date | string | null) => {
    setMockDate(value);
    localStorage.setItem("mock-date", JSON.stringify(value));
  };

  if (localStorage.getItem("mock-date")) {
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DemoContainer components={["DatePicker"]}>
          <DatePicker value={mockDate} onChange={handleDateChange} />
        </DemoContainer>
      </LocalizationProvider>
    );
  }

  return null;
};

export default MockDate;
