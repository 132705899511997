import { Action, ActionType, State } from "../types/Breadcrumb";

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.SET_BREADCRUMB:
      return { ...state, breadcrumb: action.payload };

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

export default reducer;
