import { FunctionComponent, PropsWithChildren } from "react";
import { Link } from "react-router-dom";

const BrandLogoLink: FunctionComponent<PropsWithChildren> = ({ children }) => {
  return (
    <Link to="/" style={{ textDecoration: "none" }}>
      {children}
    </Link>
  );
};

export default BrandLogoLink;
