import {
  FunctionComponent,
  PropsWithChildren,
  useMemo,
  // eslint-disable-next-line prettier/prettier
  useReducer,
} from "react";

import MessageContext from "../contexts/MessageContext";
import reducer from "../reducers/messageReducer";
import initialState from "../states/messageState";
import { Context } from "../types/Message";

const MessageProvider: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const value: Context = useMemo(
    () => ({ state, dispatch }),
    [state, dispatch]
  );

  return (
    <MessageContext.Provider value={value}>{children}</MessageContext.Provider>
  );
};

export default MessageProvider;
