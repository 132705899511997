import { Dispatch } from "react";

export interface State {
  type: string;
  message: string;
}

export interface Action {
  type: string;
  payload: State;
}

export interface Context {
  state: State;
  dispatch: Dispatch<any>;
}

export enum ActionType {
  CREATE_NEW_MESSAGE = "CREATE_NEW_MESSAGE",
  REMOVE_CREATED_MESSAGE = "REMOVE_CREATED_MESSAGE",
}
