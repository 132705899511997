import { State } from "../types/Property";

const initialState: State = {
  property: {
    authId: "",
    availableEquity: 0,
    crmId: "",
    email: "",
    estimatedValue: 0,
    fileInviteUrl: "",
    firstName: "",
    lastName: "",
    mortgageBalance: 0,
    owner: "",
    phone: "",
    propertyAddress: "",
    propertyAddressId: "",
    status: "",
    isEligible: false,
    lineOfCredit: false,
    lineOfCreditValue: 0,
    loanToValueRatio: 0,
    otherValue: 0,
    other: false,
    caveat: false,
    caveatValue: 0,
    propertyId: "",
    scheduledCall: false,
    scheduledCallUrl: "",
    createdAt: "",
    updatedAt: "",
  },
};

export default initialState;
