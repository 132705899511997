import { Action, ActionType, State } from "../types/Drawer";

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.OPEN_SIDEBAR_DRAWER:
      return { ...state, open: action.payload };

    case ActionType.CLOSE_SIDEBAR_DRAWER:
      return { ...state, open: action.payload };

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

export default reducer;
