import { Action, ActionType, State } from "../types/Authenticate";

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.SET_IS_AUTHENTICATE:
      return {
        ...state,
        authenticated: action.payload.authenticated,
      };

    case ActionType.REMOVE_IS_AUTHENTICATE:
      return {
        ...state,
        authenticated: false,
      };

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

export default reducer;
