import { notificationRedirectionLinks } from "../constants/notifications";

export const convertStringToKebabCase = (str: string) => {
  if (str.length === 0) {
    return "";
  }

  return str.toLocaleLowerCase().split(" ").join("-");
};

export const sleep = (ms: number) => {
  // eslint-disable-next-line no-promise-executor-return, prettier/prettier
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const numberToHexadecimal = (num: number) => {
  return num.toString(16).padStart(2, "0");
};

export const getFirstAndLastNameByFullName = (name: string) => {
  if (name.includes(" ")) {
    const [firstName, lastName] = name.split(" ");
    return { firstName, lastName };
  }

  return { firstName: name, lastName: "" };
};

export const getPageLinkByRedirectionType = (redirection: string) => {
  const transformRedirection = redirection
    ?.split(" ")
    ?.join("_")
    ?.toUpperCase();

  return notificationRedirectionLinks[transformRedirection] || "/";
};
