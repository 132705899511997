import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  // eslint-disable-next-line prettier/prettier
  Typography,
} from "@mui/material";
import { FunctionComponent } from "react";
import { NavLink } from "react-router-dom";

import { NavItems } from "../../types/NavItems";

interface SidebarNavigationsPropsTypes {
  items: NavItems[];
}

const SidebarNavigations: FunctionComponent<SidebarNavigationsPropsTypes> = ({
  items = [],
}) => {
  return (
    <List sx={{ background: (theme) => theme.palette.primary.main }}>
      {items.map((item) => (
        <Box key={item.id}>
          <Typography
            sx={{
              color: (theme) => theme.palette.secondary.main,
              fontWeight: "bold",
              m: 1.5,
              mt: 3,
            }}
          >
            {item.title}
          </Typography>

          {item.items.map((nav) => (
            <NavLink
              key={nav.id}
              to={nav.path}
              style={{ textDecoration: "none" }}
              className={(navItem) => (navItem.isActive ? "active" : "")}
            >
              <ListItem
                disablePadding
                className="sidebarlist"
                sx={{
                  my: 0.5,
                  color: (theme) => theme.palette.secondary.main,
                  "&:hover": {
                    background: (theme) => theme.palette.success.main,
                    color: (theme) =>
                      `${theme.palette.primary.main} !important`,
                  },
                }}
              >
                <ListItemButton className="sidebarlist-button">
                  <ListItemIcon
                    className="sidebarlist-button--icon"
                    sx={{
                      color: (theme) => theme.palette.secondary.main,
                      minWidth: 40,
                    }}
                  >
                    <nav.icon />
                  </ListItemIcon>

                  <Typography
                    className="sidebarlist-button--text"
                    sx={{ fontWeight: "bold" }}
                  >
                    {nav.name}
                  </Typography>
                </ListItemButton>
              </ListItem>
            </NavLink>
          ))}
        </Box>
      ))}
    </List>
  );
};

export default SidebarNavigations;
