import { ThemeProvider } from "@mui/material";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Amplify } from "aws-amplify";
import { HelmetProvider } from "react-helmet-async";
import { Toaster } from "react-hot-toast";
import { BrowserRouter } from "react-router-dom";

import BaseLayout from "./layout/BaseLayout";
import {
  AuthenticateProvider,
  BreadcrumbProvider,
  DrawerProvider,
  MessageProvider,
  PropertyProvider,
  // eslint-disable-next-line prettier/prettier
  UserProvider,
} from "./shared";
import theme from "./theme/defaultTheme";

const queryClient = new QueryClient();

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
    identityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID,
    mandatorySignIn: false,
  },
  aws_appsync_region: process.env.REACT_APP_AWS_APPSYNC_REGION,
  aws_appsync_graphqlEndpoint:
    process.env.REACT_APP_AWS_APPSYNC_GRAPHQL_END_POINT,
  aws_appsync_authenticationType:
    process.env.REACT_APP_AWS_APPSYNC_AUTHENTICATION_TYPE,
});

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <HelmetProvider>
          <AuthenticateProvider>
            <UserProvider>
              <DrawerProvider>
                <BreadcrumbProvider>
                  <MessageProvider>
                    <PropertyProvider>
                      <BrowserRouter>
                        <Toaster
                          position="bottom-center"
                          reverseOrder={false}
                          gutter={8}
                          containerStyle={{}}
                          toastOptions={{
                            className: "",
                            duration: 4000,
                            style: {
                              borderRadius: "10px",
                              background: "#333",
                              color: "#fff",
                            },
                          }}
                        />
                        <BaseLayout />
                      </BrowserRouter>
                    </PropertyProvider>
                  </MessageProvider>
                </BreadcrumbProvider>
              </DrawerProvider>
            </UserProvider>
          </AuthenticateProvider>
        </HelmetProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
};

export default App;
