/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getInvestorOnboarding =
  /* GraphQL */ `query GetInvestorOnboarding($email: AWSEmail!) {
  getInvestorOnboarding(email: $email) {
    email
    authId
    firstName
    lastName
    phone
    status
    propertyAddress
    propertyAddressId
    estimatedValue
    mortgageBalance
    loanToValueRatio
    availableEquity
    isEligible
    lineOfCredit
    lineOfCreditValue
    caveat
    caveatValue
    other
    otherValue
    scheduledCall
    scheduledCallUrl
    crmId
    propertyId
    fileInviteUrl
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetInvestorOnboardingQueryVariables,
    APITypes.GetInvestorOnboardingQuery
  >;
export const listInvestorOnboardings =
  /* GraphQL */ `query ListInvestorOnboardings(
  $email: AWSEmail
  $filter: ModelInvestorOnboardingFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listInvestorOnboardings(
    email: $email
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      email
      authId
      firstName
      lastName
      phone
      status
      propertyAddress
      propertyAddressId
      estimatedValue
      mortgageBalance
      loanToValueRatio
      availableEquity
      isEligible
      lineOfCredit
      lineOfCreditValue
      caveat
      caveatValue
      other
      otherValue
      scheduledCall
      scheduledCallUrl
      crmId
      propertyId
      fileInviteUrl
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.ListInvestorOnboardingsQueryVariables,
    APITypes.ListInvestorOnboardingsQuery
  >;
export const getInvestorOnboardingProperty =
  /* GraphQL */ `query GetInvestorOnboardingProperty(
  $email: AWSEmail!
  $propertyAddress: String!
  $propertyAddressId: String!
) {
  getInvestorOnboardingProperty(
    email: $email
    propertyAddress: $propertyAddress
    propertyAddressId: $propertyAddressId
  ) {
    email
    authId
    firstName
    lastName
    phone
    status
    propertyAddress
    postcode
    state
    propertyAddressId
    estimatedValue
    mortgageBalance
    loanToValueRatio
    availableEquity
    isEligible
    lineOfCredit
    lineOfCreditValue
    caveat
    caveatValue
    other
    otherValue
    scheduledCall
    scheduledCallUrl
    crmId
    propertyId
    fileInviteUrl
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetInvestorOnboardingPropertyQueryVariables,
    APITypes.GetInvestorOnboardingPropertyQuery
  >;
export const listInvestorOnboardingProperties =
  /* GraphQL */ `query ListInvestorOnboardingProperties(
  $email: AWSEmail
  $propertyAddressPropertyAddressId: ModelInvestorOnboardingPropertyPrimaryCompositeKeyConditionInput
  $filter: ModelInvestorOnboardingPropertyFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listInvestorOnboardingProperties(
    email: $email
    propertyAddressPropertyAddressId: $propertyAddressPropertyAddressId
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      email
      authId
      firstName
      lastName
      phone
      status
      propertyAddress
      postcode
      state
      propertyAddressId
      estimatedValue
      mortgageBalance
      loanToValueRatio
      availableEquity
      isEligible
      lineOfCredit
      lineOfCreditValue
      caveat
      caveatValue
      other
      otherValue
      scheduledCall
      scheduledCallUrl
      crmId
      propertyId
      fileInviteUrl
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.ListInvestorOnboardingPropertiesQueryVariables,
    APITypes.ListInvestorOnboardingPropertiesQuery
  >;
export const getInvestorReturns =
  /* GraphQL */ `query GetInvestorReturns($id: ID!) {
  getInvestorReturns(id: $id) {
    id
    email
    authId
    estimatedAnnualReturn
    estimatedNextQuarterReturn
    committedEquity
    committedEquityPercent
    uncommittedEquity
    rateOfReturn {
      month
      return
      __typename
    }
    arrearsRate {
      month
      return
      __typename
    }
    fundsAvailable
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetInvestorReturnsQueryVariables,
    APITypes.GetInvestorReturnsQuery
  >;
export const listInvestorReturns = /* GraphQL */ `query ListInvestorReturns(
  $filter: ModelInvestorReturnsFilterInput
  $limit: Int
  $nextToken: String
) {
  listInvestorReturns(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      email
      authId
      estimatedAnnualReturn
      estimatedNextQuarterReturn
      committedEquity
      committedEquityPercent
      uncommittedEquity
      fundsAvailable
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListInvestorReturnsQueryVariables,
  APITypes.ListInvestorReturnsQuery
>;
export const getInvestorReturnsData =
  /* GraphQL */ `query GetInvestorReturnsData(
  $email: AWSEmail!
  $propertyAddress: String!
  $propertyAddressId: String!
) {
  getInvestorReturnsData(
    email: $email
    propertyAddress: $propertyAddress
    propertyAddressId: $propertyAddressId
  ) {
    email
    propertyAddress
    propertyAddressId
    transactionData {
      date
      description
      amount
      transactionType
      __typename
    }
    dailyData {
      dayEnding
      estPropertyValue
      committed
      uncommitted
      available
      expectedArrears
      arrears
      expectedReturn
      return
      est90dayArrears
      estAnnualIncome
      estReturnRate
      __typename
    }
    weeklyData {
      weekEnding
      estPropertyValue
      committed
      uncommitted
      available
      expectedArrears
      arrears
      expectedReturn
      return
      est90dayArrears
      estAnnualIncome
      estReturnRate
      __typename
    }
    monthlyData {
      monthEnding
      estPropertyValue
      committed
      uncommitted
      available
      expectedArrears
      arrears
      expectedReturn
      return
      est90dayArrears
      estAnnualIncome
      estReturnRate
      __typename
    }
    quarterlyData {
      quarterEnding
      historicalReturn
      expectedReturn
      __typename
    }
    monthlyComparisonData {
      monthEnding
      housingArrearsRate
      usBondRate
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetInvestorReturnsDataQueryVariables,
    APITypes.GetInvestorReturnsDataQuery
  >;
export const listInvestorReturnsData =
  /* GraphQL */ `query ListInvestorReturnsData(
  $email: AWSEmail
  $propertyAddressPropertyAddressId: ModelInvestorReturnsDataPrimaryCompositeKeyConditionInput
  $filter: ModelInvestorReturnsDataFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listInvestorReturnsData(
    email: $email
    propertyAddressPropertyAddressId: $propertyAddressPropertyAddressId
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      email
      propertyAddress
      propertyAddressId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.ListInvestorReturnsDataQueryVariables,
    APITypes.ListInvestorReturnsDataQuery
  >;
export const getInvestorAccount =
  /* GraphQL */ `query GetInvestorAccount($id: ID!) {
  getInvestorAccount(id: $id) {
    id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetInvestorAccountQueryVariables,
    APITypes.GetInvestorAccountQuery
  >;
export const listInvestorAccounts = /* GraphQL */ `query ListInvestorAccounts(
  $filter: ModelInvestorAccountFilterInput
  $limit: Int
  $nextToken: String
) {
  listInvestorAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListInvestorAccountsQueryVariables,
  APITypes.ListInvestorAccountsQuery
>;
export const getInvestorOnboardingDataSet =
  /* GraphQL */ `query GetInvestorOnboardingDataSet(
  $email: AWSEmail!
  $propertyAddress: String!
  $propertyAddressId: String!
) {
  getInvestorOnboardingDataSet(
    email: $email
    propertyAddress: $propertyAddress
    propertyAddressId: $propertyAddressId
  ) {
    email
    owner
    propertyAddress
    propertyAddressId
    dateRange
    estimatedValue
    committed
    uncommitted
    investedAmount
    availableIncrement
    oluArrears
    returnRate
    quarterlyReturns {
      date
      amount
      __typename
    }
    estimatedAnnualIncome
    estimatedReturnRate
    estimated90DaysArrears
    combinedData {
      date
      committed
      uncommitted
      estimatedValue
      investedAmount
      availableIncrement
      oluArrears
      returnRate
      housingArrears
      usBond
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetInvestorOnboardingDataSetQueryVariables,
    APITypes.GetInvestorOnboardingDataSetQuery
  >;
export const listInvestorOnboardingDataSets =
  /* GraphQL */ `query ListInvestorOnboardingDataSets(
  $email: AWSEmail
  $propertyAddressPropertyAddressId: ModelInvestorOnboardingDataSetPrimaryCompositeKeyConditionInput
  $filter: ModelInvestorOnboardingDataSetFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listInvestorOnboardingDataSets(
    email: $email
    propertyAddressPropertyAddressId: $propertyAddressPropertyAddressId
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      email
      owner
      propertyAddress
      propertyAddressId
      dateRange
      estimatedValue
      committed
      uncommitted
      investedAmount
      availableIncrement
      oluArrears
      returnRate
      estimatedAnnualIncome
      estimatedReturnRate
      estimated90DaysArrears
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.ListInvestorOnboardingDataSetsQueryVariables,
    APITypes.ListInvestorOnboardingDataSetsQuery
  >;
export const getInvestorOnboardingUsBondDataSet =
  /* GraphQL */ `query GetInvestorOnboardingUsBondDataSet($date: AWSDate!) {
  getInvestorOnboardingUsBondDataSet(date: $date) {
    date
    usBond
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetInvestorOnboardingUsBondDataSetQueryVariables,
    APITypes.GetInvestorOnboardingUsBondDataSetQuery
  >;
export const listInvestorOnboardingUsBondDataSets =
  /* GraphQL */ `query ListInvestorOnboardingUsBondDataSets(
  $date: AWSDate
  $filter: ModelInvestorOnboardingUsBondDataSetFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listInvestorOnboardingUsBondDataSets(
    date: $date
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      date
      usBond
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.ListInvestorOnboardingUsBondDataSetsQueryVariables,
    APITypes.ListInvestorOnboardingUsBondDataSetsQuery
  >;
export const getInvestorOnboardingHousingArrearsDataSet =
  /* GraphQL */ `query GetInvestorOnboardingHousingArrearsDataSet($date: AWSDate!) {
  getInvestorOnboardingHousingArrearsDataSet(date: $date) {
    date
    housingArrears
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetInvestorOnboardingHousingArrearsDataSetQueryVariables,
    APITypes.GetInvestorOnboardingHousingArrearsDataSetQuery
  >;
export const listInvestorOnboardingHousingArrearsDataSets =
  /* GraphQL */ `query ListInvestorOnboardingHousingArrearsDataSets(
  $date: AWSDate
  $filter: ModelInvestorOnboardingHousingArrearsDataSetFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listInvestorOnboardingHousingArrearsDataSets(
    date: $date
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      date
      housingArrears
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.ListInvestorOnboardingHousingArrearsDataSetsQueryVariables,
    APITypes.ListInvestorOnboardingHousingArrearsDataSetsQuery
  >;
export const getInvestorProfile =
  /* GraphQL */ `query GetInvestorProfile($email: AWSEmail!) {
  getInvestorProfile(email: $email) {
    email
    owner
    phone
    contactId
    contactType
    secondaryEmail
    firstName
    lastName
    homePhone
    dateOfBirth
    suburb
    street
    postcode
    city
    state
    country
    address1
    address2
    isTwoFactorEnabled
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetInvestorProfileQueryVariables,
    APITypes.GetInvestorProfileQuery
  >;
export const listInvestorProfiles = /* GraphQL */ `query ListInvestorProfiles(
  $email: AWSEmail
  $filter: ModelInvestorProfileFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listInvestorProfiles(
    email: $email
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      email
      owner
      phone
      contactId
      contactType
      secondaryEmail
      firstName
      lastName
      homePhone
      dateOfBirth
      suburb
      street
      postcode
      city
      state
      country
      address1
      address2
      isTwoFactorEnabled
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListInvestorProfilesQueryVariables,
  APITypes.ListInvestorProfilesQuery
>;
export const getTransactionsData =
  /* GraphQL */ `query GetTransactionsData($email: AWSEmail!) {
  getTransactionsData(email: $email) {
    email
    owner
    dateRange
    credits
    debits
    balance
    descriptions
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetTransactionsDataQueryVariables,
    APITypes.GetTransactionsDataQuery
  >;
export const listTransactionsData = /* GraphQL */ `query ListTransactionsData(
  $email: AWSEmail
  $filter: ModelTransactionsDataFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listTransactionsData(
    email: $email
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      email
      owner
      dateRange
      credits
      debits
      balance
      descriptions
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTransactionsDataQueryVariables,
  APITypes.ListTransactionsDataQuery
>;
export const getPerformanceData =
  /* GraphQL */ `query GetPerformanceData($email: AWSEmail!) {
  getPerformanceData(email: $email) {
    email
    owner
    dates
    expectedReturns
    actualReturns
    generalHousingMarketArrears
    ourlegup90DayArrearsRatePa
    returnRatesPerAnnum
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetPerformanceDataQueryVariables,
    APITypes.GetPerformanceDataQuery
  >;
export const listPerformanceData = /* GraphQL */ `query ListPerformanceData(
  $email: AWSEmail
  $filter: ModelPerformanceDataFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listPerformanceData(
    email: $email
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      email
      owner
      dates
      expectedReturns
      actualReturns
      generalHousingMarketArrears
      ourlegup90DayArrearsRatePa
      returnRatesPerAnnum
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPerformanceDataQueryVariables,
  APITypes.ListPerformanceDataQuery
>;
export const getNotificationsData =
  /* GraphQL */ `query GetNotificationsData($email: AWSEmail!) {
  getNotificationsData(email: $email) {
    email
    owner
    dates
    times
    messages
    status
    redirection
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetNotificationsDataQueryVariables,
    APITypes.GetNotificationsDataQuery
  >;
export const listNotificationsData = /* GraphQL */ `query ListNotificationsData(
  $email: AWSEmail
  $filter: ModelNotificationsDataFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listNotificationsData(
    email: $email
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      email
      owner
      dates
      times
      messages
      status
      redirection
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListNotificationsDataQueryVariables,
  APITypes.ListNotificationsDataQuery
>;
export const getAddressSuggestions =
  /* GraphQL */ `query GetAddressSuggestions($query: String!) {
  getAddressSuggestions(query: $query) {
    suggest {
      address
      id
      rank
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetAddressSuggestionsQueryVariables,
    APITypes.GetAddressSuggestionsQuery
  >;
export const getStreetViewSignUrl =
  /* GraphQL */ `query GetStreetViewSignUrl($url: String!) {
  getStreetViewSignUrl(url: $url) {
    url
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetStreetViewSignUrlQueryVariables,
    APITypes.GetStreetViewSignUrlQuery
  >;
