import {
  FunctionComponent,
  PropsWithChildren,
  useMemo,
  // eslint-disable-next-line prettier/prettier
  useReducer,
} from "react";

import DrawerContext from "../contexts/DrawerContext";
import reducer from "../reducers/drawerReducer";
import initialState from "../states/drawerState";
import { Context } from "../types/Drawer";

const DrawerProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const value: Context = useMemo(
    () => ({ state, dispatch }),
    [state, dispatch]
  );

  return (
    <DrawerContext.Provider value={value}>{children}</DrawerContext.Provider>
  );
};

export default DrawerProvider;
