import { Action, ActionType, State } from "../types/Message";

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.CREATE_NEW_MESSAGE:
      return {
        ...state,
        type: action.payload.type,
        message: action.payload.message,
      };

    case ActionType.REMOVE_CREATED_MESSAGE:
      return {
        ...state,
        type: action.payload.type,
        message: action.payload.message,
      };

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

export default reducer;
