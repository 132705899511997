import { Dispatch } from "react";
import { OnboardingStatus } from "../../models";

export interface Property {
  authId: string;
  availableEquity: number;
  crmId: string | null;
  email: string;
  estimatedValue: number;
  fileInviteUrl: string | null;
  firstName: string;
  lastName: string;
  mortgageBalance: number;
  owner: string;
  phone: string;
  propertyAddress: string;
  propertyAddressId: string;
  status: OnboardingStatus | string;
  isEligible?: boolean;
  lineOfCredit?: boolean;
  lineOfCreditValue?: number | boolean;
  loanToValueRatio?: number;
  otherValue?: number | null;
  other?: boolean;
  caveat?: boolean;
  caveatValue?: number | null;
  propertyId?: string | null;
  scheduledCall?: boolean | null;
  scheduledCallUrl?: string | null;
  createdAt?: string;
  updatedAt?: string;
}

export interface State {
  property: Property;
}

export interface Action {
  type: string;
  payload: State;
}

export interface Context {
  state: State;
  dispatch: Dispatch<any>;
}

export enum ActionType {
  SET_INVESTOR_PROPERTY = "SET_INVESTOR_PROPERTY",
  REMOVE_INVESTOR_PROPERTY = "REMOVE_INVESTOR_PROPERTY",
}
