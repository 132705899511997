import { createContext } from "react";

import initialState from "../states/userState";
import { Context } from "../types/User";

const contextInitialState: Context = {
  state: initialState,
  dispatch: () => {},
};

const UserContext = createContext<Context>(contextInitialState);

export default UserContext;
