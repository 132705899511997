import {
  Dashboard,
  Description,
  Repeat,
  VolunteerActivism,
} from "@mui/icons-material";

import { NavItems } from "../types/NavItems";

export const navItems: NavItems[] = [
  {
    id: "eff3b49c-2da4-4rfe-asdf-0242ac150001",
    title: "",
    items: [
      {
        id: "eff3b49c-2da4-4rfe-asdf-0242ac150011",
        name: "Dashboard",
        path: "/dashboard",
        icon: Dashboard,
      },
      {
        id: "eff3b49c-2da4-4rfe-asdf-0242ac150012",
        name: "Investment Management",
        path: "/investment-management",
        icon: VolunteerActivism,
      },
      {
        id: "eff3b49c-2da4-4rfe-asdf-0242ac150013",
        name: "Performance",
        path: "/performance",
        icon: Description,
      },
      {
        id: "eff3b49c-2da4-4rfe-asdf-0242ac150014",
        name: "Onboarding",
        path: "/property-onboarding",
        icon: Repeat,
      },
    ],
  },
];
