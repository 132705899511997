import { Dispatch } from "react";

import { ActionType, State } from "../types/User";

export const setUserDetails = (dispatch: Dispatch<any>, payload: State) => {
  localStorage.setItem("user-details", JSON.stringify(payload.user));
  return dispatch({
    type: ActionType.SET_USER_DETAILS,
    payload: payload.user,
  });
};

export const removeUserDetails = (dispatch: Dispatch<any>) => {
  const payload = {
    user: {
      email: "",
      family_name: "",
      given_name: "",
      name: "",
      phone_number: "",
      email_verified: false,
      phone_number_verified: false,
      sub: "",
    },
  };

  return dispatch({
    type: ActionType.REMOVE_USER_DETAILS,
    payload: payload.user,
  });
};
