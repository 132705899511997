import { Dispatch } from "react";

import { ActionType } from "../types/Breadcrumb";

export const setBreadcrumb = (dispatch: Dispatch<any>, payload: string) => {
  return dispatch({
    type: ActionType.SET_BREADCRUMB,
    payload,
  });
};
