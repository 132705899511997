import { Divider, ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { NavItem } from "../../types/NavItems";

interface ItemOfMenuPropsTypes {
  item: NavItem;
}

const ItemOfMenu: FunctionComponent<ItemOfMenuPropsTypes> = ({ item }) => {
  const menuItemWithIcon = (
    <MenuItem>
      <ListItemIcon
        sx={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          gap: 1.5,
          color: (theme) => theme.palette.primary.main,
        }}
      >
        <item.icon />
      </ListItemIcon>

      <ListItemText
        primary={item.name}
        sx={{ color: (theme) => theme.palette.primary.main }}
      />
    </MenuItem>
  );

  if (item?.openInNewTab) {
    return (
      <>
        <a
          href={item.path}
          style={{ textDecoration: "none" }}
          target="_blank"
          rel="noreferrer"
        >
          {menuItemWithIcon}
        </a>

        {item.divider && <Divider />}
      </>
    );
  }

  return (
    <>
      <Link to={item.path} style={{ textDecoration: "none" }}>
        {menuItemWithIcon}
      </Link>

      {item.divider && <Divider />}
    </>
  );
};

export default ItemOfMenu;
