import {
  FunctionComponent,
  PropsWithChildren,
  useMemo,
  // eslint-disable-next-line prettier/prettier
  useReducer,
} from "react";

import UserContext from "../contexts/UserContext";
import reducer from "../reducers/userReducer";
import initialState from "../states/userState";
import { Context } from "../types/User";

const UserProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const value: Context = useMemo(
    () => ({ state, dispatch }),
    [state, dispatch]
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export default UserProvider;
