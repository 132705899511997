/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const onCreateInvestorOnboarding =
  /* GraphQL */ `subscription OnCreateInvestorOnboarding(
  $filter: ModelSubscriptionInvestorOnboardingFilterInput
  $owner: String
) {
  onCreateInvestorOnboarding(filter: $filter, owner: $owner) {
    email
    authId
    firstName
    lastName
    phone
    status
    propertyAddress
    propertyAddressId
    estimatedValue
    mortgageBalance
    loanToValueRatio
    availableEquity
    isEligible
    lineOfCredit
    lineOfCreditValue
    caveat
    caveatValue
    other
    otherValue
    scheduledCall
    scheduledCallUrl
    crmId
    propertyId
    fileInviteUrl
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnCreateInvestorOnboardingSubscriptionVariables,
    APITypes.OnCreateInvestorOnboardingSubscription
  >;
export const onUpdateInvestorOnboarding =
  /* GraphQL */ `subscription OnUpdateInvestorOnboarding(
  $filter: ModelSubscriptionInvestorOnboardingFilterInput
  $owner: String
) {
  onUpdateInvestorOnboarding(filter: $filter, owner: $owner) {
    email
    authId
    firstName
    lastName
    phone
    status
    propertyAddress
    propertyAddressId
    estimatedValue
    mortgageBalance
    loanToValueRatio
    availableEquity
    isEligible
    lineOfCredit
    lineOfCreditValue
    caveat
    caveatValue
    other
    otherValue
    scheduledCall
    scheduledCallUrl
    crmId
    propertyId
    fileInviteUrl
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnUpdateInvestorOnboardingSubscriptionVariables,
    APITypes.OnUpdateInvestorOnboardingSubscription
  >;
export const onDeleteInvestorOnboarding =
  /* GraphQL */ `subscription OnDeleteInvestorOnboarding(
  $filter: ModelSubscriptionInvestorOnboardingFilterInput
  $owner: String
) {
  onDeleteInvestorOnboarding(filter: $filter, owner: $owner) {
    email
    authId
    firstName
    lastName
    phone
    status
    propertyAddress
    propertyAddressId
    estimatedValue
    mortgageBalance
    loanToValueRatio
    availableEquity
    isEligible
    lineOfCredit
    lineOfCreditValue
    caveat
    caveatValue
    other
    otherValue
    scheduledCall
    scheduledCallUrl
    crmId
    propertyId
    fileInviteUrl
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnDeleteInvestorOnboardingSubscriptionVariables,
    APITypes.OnDeleteInvestorOnboardingSubscription
  >;
export const onCreateInvestorOnboardingProperty =
  /* GraphQL */ `subscription OnCreateInvestorOnboardingProperty(
  $filter: ModelSubscriptionInvestorOnboardingPropertyFilterInput
  $owner: String
) {
  onCreateInvestorOnboardingProperty(filter: $filter, owner: $owner) {
    email
    authId
    firstName
    lastName
    phone
    status
    propertyAddress
    postcode
    state
    propertyAddressId
    estimatedValue
    mortgageBalance
    loanToValueRatio
    availableEquity
    isEligible
    lineOfCredit
    lineOfCreditValue
    caveat
    caveatValue
    other
    otherValue
    scheduledCall
    scheduledCallUrl
    crmId
    propertyId
    fileInviteUrl
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnCreateInvestorOnboardingPropertySubscriptionVariables,
    APITypes.OnCreateInvestorOnboardingPropertySubscription
  >;
export const onUpdateInvestorOnboardingProperty =
  /* GraphQL */ `subscription OnUpdateInvestorOnboardingProperty(
  $filter: ModelSubscriptionInvestorOnboardingPropertyFilterInput
  $owner: String
) {
  onUpdateInvestorOnboardingProperty(filter: $filter, owner: $owner) {
    email
    authId
    firstName
    lastName
    phone
    status
    propertyAddress
    postcode
    state
    propertyAddressId
    estimatedValue
    mortgageBalance
    loanToValueRatio
    availableEquity
    isEligible
    lineOfCredit
    lineOfCreditValue
    caveat
    caveatValue
    other
    otherValue
    scheduledCall
    scheduledCallUrl
    crmId
    propertyId
    fileInviteUrl
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnUpdateInvestorOnboardingPropertySubscriptionVariables,
    APITypes.OnUpdateInvestorOnboardingPropertySubscription
  >;
export const onDeleteInvestorOnboardingProperty =
  /* GraphQL */ `subscription OnDeleteInvestorOnboardingProperty(
  $filter: ModelSubscriptionInvestorOnboardingPropertyFilterInput
  $owner: String
) {
  onDeleteInvestorOnboardingProperty(filter: $filter, owner: $owner) {
    email
    authId
    firstName
    lastName
    phone
    status
    propertyAddress
    postcode
    state
    propertyAddressId
    estimatedValue
    mortgageBalance
    loanToValueRatio
    availableEquity
    isEligible
    lineOfCredit
    lineOfCreditValue
    caveat
    caveatValue
    other
    otherValue
    scheduledCall
    scheduledCallUrl
    crmId
    propertyId
    fileInviteUrl
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnDeleteInvestorOnboardingPropertySubscriptionVariables,
    APITypes.OnDeleteInvestorOnboardingPropertySubscription
  >;
export const onCreateInvestorReturns =
  /* GraphQL */ `subscription OnCreateInvestorReturns(
  $filter: ModelSubscriptionInvestorReturnsFilterInput
  $owner: String
) {
  onCreateInvestorReturns(filter: $filter, owner: $owner) {
    id
    email
    authId
    estimatedAnnualReturn
    estimatedNextQuarterReturn
    committedEquity
    committedEquityPercent
    uncommittedEquity
    rateOfReturn {
      month
      return
      __typename
    }
    arrearsRate {
      month
      return
      __typename
    }
    fundsAvailable
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnCreateInvestorReturnsSubscriptionVariables,
    APITypes.OnCreateInvestorReturnsSubscription
  >;
export const onUpdateInvestorReturns =
  /* GraphQL */ `subscription OnUpdateInvestorReturns(
  $filter: ModelSubscriptionInvestorReturnsFilterInput
  $owner: String
) {
  onUpdateInvestorReturns(filter: $filter, owner: $owner) {
    id
    email
    authId
    estimatedAnnualReturn
    estimatedNextQuarterReturn
    committedEquity
    committedEquityPercent
    uncommittedEquity
    rateOfReturn {
      month
      return
      __typename
    }
    arrearsRate {
      month
      return
      __typename
    }
    fundsAvailable
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnUpdateInvestorReturnsSubscriptionVariables,
    APITypes.OnUpdateInvestorReturnsSubscription
  >;
export const onDeleteInvestorReturns =
  /* GraphQL */ `subscription OnDeleteInvestorReturns(
  $filter: ModelSubscriptionInvestorReturnsFilterInput
  $owner: String
) {
  onDeleteInvestorReturns(filter: $filter, owner: $owner) {
    id
    email
    authId
    estimatedAnnualReturn
    estimatedNextQuarterReturn
    committedEquity
    committedEquityPercent
    uncommittedEquity
    rateOfReturn {
      month
      return
      __typename
    }
    arrearsRate {
      month
      return
      __typename
    }
    fundsAvailable
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnDeleteInvestorReturnsSubscriptionVariables,
    APITypes.OnDeleteInvestorReturnsSubscription
  >;
export const onCreateInvestorReturnsData =
  /* GraphQL */ `subscription OnCreateInvestorReturnsData(
  $filter: ModelSubscriptionInvestorReturnsDataFilterInput
  $owner: String
) {
  onCreateInvestorReturnsData(filter: $filter, owner: $owner) {
    email
    propertyAddress
    propertyAddressId
    transactionData {
      date
      description
      amount
      transactionType
      __typename
    }
    dailyData {
      dayEnding
      estPropertyValue
      committed
      uncommitted
      available
      expectedArrears
      arrears
      expectedReturn
      return
      est90dayArrears
      estAnnualIncome
      estReturnRate
      __typename
    }
    weeklyData {
      weekEnding
      estPropertyValue
      committed
      uncommitted
      available
      expectedArrears
      arrears
      expectedReturn
      return
      est90dayArrears
      estAnnualIncome
      estReturnRate
      __typename
    }
    monthlyData {
      monthEnding
      estPropertyValue
      committed
      uncommitted
      available
      expectedArrears
      arrears
      expectedReturn
      return
      est90dayArrears
      estAnnualIncome
      estReturnRate
      __typename
    }
    quarterlyData {
      quarterEnding
      historicalReturn
      expectedReturn
      __typename
    }
    monthlyComparisonData {
      monthEnding
      housingArrearsRate
      usBondRate
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnCreateInvestorReturnsDataSubscriptionVariables,
    APITypes.OnCreateInvestorReturnsDataSubscription
  >;
export const onUpdateInvestorReturnsData =
  /* GraphQL */ `subscription OnUpdateInvestorReturnsData(
  $filter: ModelSubscriptionInvestorReturnsDataFilterInput
  $owner: String
) {
  onUpdateInvestorReturnsData(filter: $filter, owner: $owner) {
    email
    propertyAddress
    propertyAddressId
    transactionData {
      date
      description
      amount
      transactionType
      __typename
    }
    dailyData {
      dayEnding
      estPropertyValue
      committed
      uncommitted
      available
      expectedArrears
      arrears
      expectedReturn
      return
      est90dayArrears
      estAnnualIncome
      estReturnRate
      __typename
    }
    weeklyData {
      weekEnding
      estPropertyValue
      committed
      uncommitted
      available
      expectedArrears
      arrears
      expectedReturn
      return
      est90dayArrears
      estAnnualIncome
      estReturnRate
      __typename
    }
    monthlyData {
      monthEnding
      estPropertyValue
      committed
      uncommitted
      available
      expectedArrears
      arrears
      expectedReturn
      return
      est90dayArrears
      estAnnualIncome
      estReturnRate
      __typename
    }
    quarterlyData {
      quarterEnding
      historicalReturn
      expectedReturn
      __typename
    }
    monthlyComparisonData {
      monthEnding
      housingArrearsRate
      usBondRate
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnUpdateInvestorReturnsDataSubscriptionVariables,
    APITypes.OnUpdateInvestorReturnsDataSubscription
  >;
export const onDeleteInvestorReturnsData =
  /* GraphQL */ `subscription OnDeleteInvestorReturnsData(
  $filter: ModelSubscriptionInvestorReturnsDataFilterInput
  $owner: String
) {
  onDeleteInvestorReturnsData(filter: $filter, owner: $owner) {
    email
    propertyAddress
    propertyAddressId
    transactionData {
      date
      description
      amount
      transactionType
      __typename
    }
    dailyData {
      dayEnding
      estPropertyValue
      committed
      uncommitted
      available
      expectedArrears
      arrears
      expectedReturn
      return
      est90dayArrears
      estAnnualIncome
      estReturnRate
      __typename
    }
    weeklyData {
      weekEnding
      estPropertyValue
      committed
      uncommitted
      available
      expectedArrears
      arrears
      expectedReturn
      return
      est90dayArrears
      estAnnualIncome
      estReturnRate
      __typename
    }
    monthlyData {
      monthEnding
      estPropertyValue
      committed
      uncommitted
      available
      expectedArrears
      arrears
      expectedReturn
      return
      est90dayArrears
      estAnnualIncome
      estReturnRate
      __typename
    }
    quarterlyData {
      quarterEnding
      historicalReturn
      expectedReturn
      __typename
    }
    monthlyComparisonData {
      monthEnding
      housingArrearsRate
      usBondRate
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnDeleteInvestorReturnsDataSubscriptionVariables,
    APITypes.OnDeleteInvestorReturnsDataSubscription
  >;
export const onCreateInvestorAccount =
  /* GraphQL */ `subscription OnCreateInvestorAccount(
  $filter: ModelSubscriptionInvestorAccountFilterInput
  $owner: String
) {
  onCreateInvestorAccount(filter: $filter, owner: $owner) {
    id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnCreateInvestorAccountSubscriptionVariables,
    APITypes.OnCreateInvestorAccountSubscription
  >;
export const onUpdateInvestorAccount =
  /* GraphQL */ `subscription OnUpdateInvestorAccount(
  $filter: ModelSubscriptionInvestorAccountFilterInput
  $owner: String
) {
  onUpdateInvestorAccount(filter: $filter, owner: $owner) {
    id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnUpdateInvestorAccountSubscriptionVariables,
    APITypes.OnUpdateInvestorAccountSubscription
  >;
export const onDeleteInvestorAccount =
  /* GraphQL */ `subscription OnDeleteInvestorAccount(
  $filter: ModelSubscriptionInvestorAccountFilterInput
  $owner: String
) {
  onDeleteInvestorAccount(filter: $filter, owner: $owner) {
    id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnDeleteInvestorAccountSubscriptionVariables,
    APITypes.OnDeleteInvestorAccountSubscription
  >;
export const onCreateInvestorOnboardingDataSet =
  /* GraphQL */ `subscription OnCreateInvestorOnboardingDataSet(
  $filter: ModelSubscriptionInvestorOnboardingDataSetFilterInput
  $owner: String
) {
  onCreateInvestorOnboardingDataSet(filter: $filter, owner: $owner) {
    email
    owner
    propertyAddress
    propertyAddressId
    dateRange
    estimatedValue
    committed
    uncommitted
    investedAmount
    availableIncrement
    oluArrears
    returnRate
    quarterlyReturns {
      date
      amount
      __typename
    }
    estimatedAnnualIncome
    estimatedReturnRate
    estimated90DaysArrears
    combinedData {
      date
      committed
      uncommitted
      estimatedValue
      investedAmount
      availableIncrement
      oluArrears
      returnRate
      housingArrears
      usBond
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnCreateInvestorOnboardingDataSetSubscriptionVariables,
    APITypes.OnCreateInvestorOnboardingDataSetSubscription
  >;
export const onUpdateInvestorOnboardingDataSet =
  /* GraphQL */ `subscription OnUpdateInvestorOnboardingDataSet(
  $filter: ModelSubscriptionInvestorOnboardingDataSetFilterInput
  $owner: String
) {
  onUpdateInvestorOnboardingDataSet(filter: $filter, owner: $owner) {
    email
    owner
    propertyAddress
    propertyAddressId
    dateRange
    estimatedValue
    committed
    uncommitted
    investedAmount
    availableIncrement
    oluArrears
    returnRate
    quarterlyReturns {
      date
      amount
      __typename
    }
    estimatedAnnualIncome
    estimatedReturnRate
    estimated90DaysArrears
    combinedData {
      date
      committed
      uncommitted
      estimatedValue
      investedAmount
      availableIncrement
      oluArrears
      returnRate
      housingArrears
      usBond
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnUpdateInvestorOnboardingDataSetSubscriptionVariables,
    APITypes.OnUpdateInvestorOnboardingDataSetSubscription
  >;
export const onDeleteInvestorOnboardingDataSet =
  /* GraphQL */ `subscription OnDeleteInvestorOnboardingDataSet(
  $filter: ModelSubscriptionInvestorOnboardingDataSetFilterInput
  $owner: String
) {
  onDeleteInvestorOnboardingDataSet(filter: $filter, owner: $owner) {
    email
    owner
    propertyAddress
    propertyAddressId
    dateRange
    estimatedValue
    committed
    uncommitted
    investedAmount
    availableIncrement
    oluArrears
    returnRate
    quarterlyReturns {
      date
      amount
      __typename
    }
    estimatedAnnualIncome
    estimatedReturnRate
    estimated90DaysArrears
    combinedData {
      date
      committed
      uncommitted
      estimatedValue
      investedAmount
      availableIncrement
      oluArrears
      returnRate
      housingArrears
      usBond
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnDeleteInvestorOnboardingDataSetSubscriptionVariables,
    APITypes.OnDeleteInvestorOnboardingDataSetSubscription
  >;
export const onCreateInvestorOnboardingUsBondDataSet =
  /* GraphQL */ `subscription OnCreateInvestorOnboardingUsBondDataSet(
  $filter: ModelSubscriptionInvestorOnboardingUsBondDataSetFilterInput
  $owner: String
) {
  onCreateInvestorOnboardingUsBondDataSet(filter: $filter, owner: $owner) {
    date
    usBond
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnCreateInvestorOnboardingUsBondDataSetSubscriptionVariables,
    APITypes.OnCreateInvestorOnboardingUsBondDataSetSubscription
  >;
export const onUpdateInvestorOnboardingUsBondDataSet =
  /* GraphQL */ `subscription OnUpdateInvestorOnboardingUsBondDataSet(
  $filter: ModelSubscriptionInvestorOnboardingUsBondDataSetFilterInput
  $owner: String
) {
  onUpdateInvestorOnboardingUsBondDataSet(filter: $filter, owner: $owner) {
    date
    usBond
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnUpdateInvestorOnboardingUsBondDataSetSubscriptionVariables,
    APITypes.OnUpdateInvestorOnboardingUsBondDataSetSubscription
  >;
export const onDeleteInvestorOnboardingUsBondDataSet =
  /* GraphQL */ `subscription OnDeleteInvestorOnboardingUsBondDataSet(
  $filter: ModelSubscriptionInvestorOnboardingUsBondDataSetFilterInput
  $owner: String
) {
  onDeleteInvestorOnboardingUsBondDataSet(filter: $filter, owner: $owner) {
    date
    usBond
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnDeleteInvestorOnboardingUsBondDataSetSubscriptionVariables,
    APITypes.OnDeleteInvestorOnboardingUsBondDataSetSubscription
  >;
export const onCreateInvestorOnboardingHousingArrearsDataSet =
  /* GraphQL */ `subscription OnCreateInvestorOnboardingHousingArrearsDataSet(
  $filter: ModelSubscriptionInvestorOnboardingHousingArrearsDataSetFilterInput
  $owner: String
) {
  onCreateInvestorOnboardingHousingArrearsDataSet(
    filter: $filter
    owner: $owner
  ) {
    date
    housingArrears
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnCreateInvestorOnboardingHousingArrearsDataSetSubscriptionVariables,
    APITypes.OnCreateInvestorOnboardingHousingArrearsDataSetSubscription
  >;
export const onUpdateInvestorOnboardingHousingArrearsDataSet =
  /* GraphQL */ `subscription OnUpdateInvestorOnboardingHousingArrearsDataSet(
  $filter: ModelSubscriptionInvestorOnboardingHousingArrearsDataSetFilterInput
  $owner: String
) {
  onUpdateInvestorOnboardingHousingArrearsDataSet(
    filter: $filter
    owner: $owner
  ) {
    date
    housingArrears
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnUpdateInvestorOnboardingHousingArrearsDataSetSubscriptionVariables,
    APITypes.OnUpdateInvestorOnboardingHousingArrearsDataSetSubscription
  >;
export const onDeleteInvestorOnboardingHousingArrearsDataSet =
  /* GraphQL */ `subscription OnDeleteInvestorOnboardingHousingArrearsDataSet(
  $filter: ModelSubscriptionInvestorOnboardingHousingArrearsDataSetFilterInput
  $owner: String
) {
  onDeleteInvestorOnboardingHousingArrearsDataSet(
    filter: $filter
    owner: $owner
  ) {
    date
    housingArrears
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnDeleteInvestorOnboardingHousingArrearsDataSetSubscriptionVariables,
    APITypes.OnDeleteInvestorOnboardingHousingArrearsDataSetSubscription
  >;
export const onCreateInvestorProfile =
  /* GraphQL */ `subscription OnCreateInvestorProfile(
  $filter: ModelSubscriptionInvestorProfileFilterInput
  $owner: String
) {
  onCreateInvestorProfile(filter: $filter, owner: $owner) {
    email
    owner
    phone
    contactId
    contactType
    secondaryEmail
    firstName
    lastName
    homePhone
    dateOfBirth
    suburb
    street
    postcode
    city
    state
    country
    address1
    address2
    isTwoFactorEnabled
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnCreateInvestorProfileSubscriptionVariables,
    APITypes.OnCreateInvestorProfileSubscription
  >;
export const onUpdateInvestorProfile =
  /* GraphQL */ `subscription OnUpdateInvestorProfile(
  $filter: ModelSubscriptionInvestorProfileFilterInput
  $owner: String
) {
  onUpdateInvestorProfile(filter: $filter, owner: $owner) {
    email
    owner
    phone
    contactId
    contactType
    secondaryEmail
    firstName
    lastName
    homePhone
    dateOfBirth
    suburb
    street
    postcode
    city
    state
    country
    address1
    address2
    isTwoFactorEnabled
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnUpdateInvestorProfileSubscriptionVariables,
    APITypes.OnUpdateInvestorProfileSubscription
  >;
export const onDeleteInvestorProfile =
  /* GraphQL */ `subscription OnDeleteInvestorProfile(
  $filter: ModelSubscriptionInvestorProfileFilterInput
  $owner: String
) {
  onDeleteInvestorProfile(filter: $filter, owner: $owner) {
    email
    owner
    phone
    contactId
    contactType
    secondaryEmail
    firstName
    lastName
    homePhone
    dateOfBirth
    suburb
    street
    postcode
    city
    state
    country
    address1
    address2
    isTwoFactorEnabled
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnDeleteInvestorProfileSubscriptionVariables,
    APITypes.OnDeleteInvestorProfileSubscription
  >;
export const onCreateTransactionsData =
  /* GraphQL */ `subscription OnCreateTransactionsData(
  $filter: ModelSubscriptionTransactionsDataFilterInput
  $owner: String
) {
  onCreateTransactionsData(filter: $filter, owner: $owner) {
    email
    owner
    dateRange
    credits
    debits
    balance
    descriptions
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnCreateTransactionsDataSubscriptionVariables,
    APITypes.OnCreateTransactionsDataSubscription
  >;
export const onUpdateTransactionsData =
  /* GraphQL */ `subscription OnUpdateTransactionsData(
  $filter: ModelSubscriptionTransactionsDataFilterInput
  $owner: String
) {
  onUpdateTransactionsData(filter: $filter, owner: $owner) {
    email
    owner
    dateRange
    credits
    debits
    balance
    descriptions
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnUpdateTransactionsDataSubscriptionVariables,
    APITypes.OnUpdateTransactionsDataSubscription
  >;
export const onDeleteTransactionsData =
  /* GraphQL */ `subscription OnDeleteTransactionsData(
  $filter: ModelSubscriptionTransactionsDataFilterInput
  $owner: String
) {
  onDeleteTransactionsData(filter: $filter, owner: $owner) {
    email
    owner
    dateRange
    credits
    debits
    balance
    descriptions
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnDeleteTransactionsDataSubscriptionVariables,
    APITypes.OnDeleteTransactionsDataSubscription
  >;
export const onCreatePerformanceData =
  /* GraphQL */ `subscription OnCreatePerformanceData(
  $filter: ModelSubscriptionPerformanceDataFilterInput
  $owner: String
) {
  onCreatePerformanceData(filter: $filter, owner: $owner) {
    email
    owner
    dates
    expectedReturns
    actualReturns
    generalHousingMarketArrears
    ourlegup90DayArrearsRatePa
    returnRatesPerAnnum
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnCreatePerformanceDataSubscriptionVariables,
    APITypes.OnCreatePerformanceDataSubscription
  >;
export const onUpdatePerformanceData =
  /* GraphQL */ `subscription OnUpdatePerformanceData(
  $filter: ModelSubscriptionPerformanceDataFilterInput
  $owner: String
) {
  onUpdatePerformanceData(filter: $filter, owner: $owner) {
    email
    owner
    dates
    expectedReturns
    actualReturns
    generalHousingMarketArrears
    ourlegup90DayArrearsRatePa
    returnRatesPerAnnum
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnUpdatePerformanceDataSubscriptionVariables,
    APITypes.OnUpdatePerformanceDataSubscription
  >;
export const onDeletePerformanceData =
  /* GraphQL */ `subscription OnDeletePerformanceData(
  $filter: ModelSubscriptionPerformanceDataFilterInput
  $owner: String
) {
  onDeletePerformanceData(filter: $filter, owner: $owner) {
    email
    owner
    dates
    expectedReturns
    actualReturns
    generalHousingMarketArrears
    ourlegup90DayArrearsRatePa
    returnRatesPerAnnum
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnDeletePerformanceDataSubscriptionVariables,
    APITypes.OnDeletePerformanceDataSubscription
  >;
export const onCreateNotificationsData =
  /* GraphQL */ `subscription OnCreateNotificationsData(
  $filter: ModelSubscriptionNotificationsDataFilterInput
  $owner: String
) {
  onCreateNotificationsData(filter: $filter, owner: $owner) {
    email
    owner
    dates
    times
    messages
    status
    redirection
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnCreateNotificationsDataSubscriptionVariables,
    APITypes.OnCreateNotificationsDataSubscription
  >;
export const onUpdateNotificationsData =
  /* GraphQL */ `subscription OnUpdateNotificationsData(
  $filter: ModelSubscriptionNotificationsDataFilterInput
  $owner: String
) {
  onUpdateNotificationsData(filter: $filter, owner: $owner) {
    email
    owner
    dates
    times
    messages
    status
    redirection
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnUpdateNotificationsDataSubscriptionVariables,
    APITypes.OnUpdateNotificationsDataSubscription
  >;
export const onDeleteNotificationsData =
  /* GraphQL */ `subscription OnDeleteNotificationsData(
  $filter: ModelSubscriptionNotificationsDataFilterInput
  $owner: String
) {
  onDeleteNotificationsData(filter: $filter, owner: $owner) {
    email
    owner
    dates
    times
    messages
    status
    redirection
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
    APITypes.OnDeleteNotificationsDataSubscriptionVariables,
    APITypes.OnDeleteNotificationsDataSubscription
  >;
