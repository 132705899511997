import {
  FunctionComponent,
  PropsWithChildren,
  useMemo,
  // eslint-disable-next-line prettier/prettier
  useReducer,
} from "react";

import AuthenticateContext from "../contexts/AuthenticateContext";
import reducer from "../reducers/authenticateReducer";
import initialState from "../states/authenticateState";
import { Context } from "../types/Authenticate";

const AuthenticateProvider: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const value: Context = useMemo(
    () => ({ state, dispatch }),
    [state, dispatch]
  );

  return (
    <AuthenticateContext.Provider value={value}>
      {children}
    </AuthenticateContext.Provider>
  );
};

export default AuthenticateProvider;
