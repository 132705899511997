import { Action, ActionType, State } from "../types/Property";

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.SET_INVESTOR_PROPERTY:
      return {
        ...state,
        property: action.payload.property,
      };

    case ActionType.REMOVE_INVESTOR_PROPERTY:
      return {
        ...state,
        property: action.payload.property,
      };

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

export default reducer;
