/* eslint-disable no-nested-ternary */
import { FunctionComponent, PropsWithChildren } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import useAuthenticate from "../hooks/useAuthenticate";
import RouteType from "../types/Route";

export const AuthenticatedRoute: FunctionComponent<PropsWithChildren> = ({
  children,
}): any => {
  const { state: authState } = useAuthenticate();
  return authState.authenticated ? children : <Navigate to="/" />;
};

const NotAuthenticatedNotProtected: FunctionComponent<PropsWithChildren> = ({
  children,
}): any => {
  return children;
};

interface UnAuthenticatedPropsTypes {
  // eslint-disable-next-line react/require-default-props
  isPublic?: boolean;
}

const UnAuthenticated: FunctionComponent<
  PropsWithChildren<UnAuthenticatedPropsTypes>
> = ({ children, isPublic }): any => {
  const { state: authState } = useAuthenticate();
  return !authState.authenticated || isPublic ? (
    children
  ) : (
    <Navigate to="/dashboard" />
  );
};

interface RouteConfigPropsTypes {
  routes: RouteType[];
}

const RouteConfig: FunctionComponent<RouteConfigPropsTypes> = ({
  routes = [],
}) => {
  return (
    <Routes>
      {routes.map((route) => (
        <Route
          key={route.id}
          path={route.path}
          element={
            route.protected && !route.public ? (
              <AuthenticatedRoute>
                <route.component />
              </AuthenticatedRoute>
            ) : route.public ? (
              <NotAuthenticatedNotProtected>
                <route.component />
              </NotAuthenticatedNotProtected>
            ) : (
              <UnAuthenticated isPublic={route?.public}>
                <route.component />
              </UnAuthenticated>
            )
          }
        />
      ))}
    </Routes>
  );
};

export default RouteConfig;
