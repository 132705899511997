import { Dispatch } from "react";

import { ActionType } from "../types/Drawer";

export const openDrawer = (dispatch: Dispatch<any>) => {
  return dispatch({
    type: ActionType.OPEN_SIDEBAR_DRAWER,
    payload: true,
  });
};

export const closeDrawer = (dispatch: Dispatch<any>) => {
  return dispatch({
    type: ActionType.CLOSE_SIDEBAR_DRAWER,
    payload: false,
  });
};
