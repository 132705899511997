import { Typography } from "@mui/material";
import useBreadcrumb from "../../hooks/useBreadcrumb";

const Breadcrumb = () => {
  const { state } = useBreadcrumb();

  return (
    <Typography
      variant="h4"
      noWrap
      color="primary"
      fontWeight="bold"
      className="font-argent"
      sx={{ fontSize: { xs: 22, sm: 36 } }}
    >
      {state.breadcrumb}
    </Typography>
  );
};

export default Breadcrumb;
