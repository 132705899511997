import { Dispatch } from "react";

import { ActionType, State } from "../types/Message";

export const setMessage = (dispatch: Dispatch<any>, payload: State) => {
  return dispatch({
    type: ActionType.CREATE_NEW_MESSAGE,
    payload,
  });
};

export const removeMessage = (dispatch: Dispatch<any>) => {
  return dispatch({
    type: ActionType.REMOVE_CREATED_MESSAGE,
    payload: { type: "", message: "" },
  });
};
