// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const OnboardingStatus = {
  "ACCOUNT_CREATED": "ACCOUNT_CREATED",
  "PERSONAL_INFORMATION": "PERSONAL_INFORMATION",
  "INTRO_CALL": "INTRO_CALL",
  "COMPLETE_REGISTRATION": "COMPLETE_REGISTRATION",
  "INTRO_CALL_SCHEDULED": "INTRO_CALL_SCHEDULED",
  "UPLOAD_DOCUMENTS": "UPLOAD_DOCUMENTS",
  "PROPERTY_VALUATION": "PROPERTY_VALUATION",
  "REVIEW_OF_FILES": "REVIEW_OF_FILES",
  "SECOND_RANK_MORTGAGE_PROCESS": "SECOND_RANK_MORTGAGE_PROCESS",
  "LAND_TITLE_REGISTRATION": "LAND_TITLE_REGISTRATION",
  "CLOSED_LOST": "CLOSED_LOST",
  "CLOSED_WON": "CLOSED_WON"
};

const { InvestorOnboarding, InvestorReturns, GeoScapeAddresses, GeoScapeAddress, MonthlyRate } = initSchema(schema);

export {
  InvestorOnboarding,
  InvestorReturns,
  OnboardingStatus,
  GeoScapeAddresses,
  GeoScapeAddress,
  MonthlyRate
};