import { API, graphqlOperation } from "aws-amplify";

import { createInvestorOnboardingProperty as createInvestorOnboarding } from "../graphql/mutations";
import { listInvestorOnboardingProperties as listInvestorOnboardings } from "../graphql/queries";
import { IEligibilityCheckType } from "../types/EligibilityCheck";

export const createInvestorOnboardingAccount = async (
  data: IEligibilityCheckType
) => {
  try {
    const response = await API.graphql({
      query: createInvestorOnboarding,
      variables: {
        input: data,
      },
    });

    return response;
  } catch (error) {
    console.log({ error });
    throw new Error(
      "Could not create investor account right now, Try again later"
    );
  }
};

export const getInvestorOnboardingAccount = async (email: string = "") => {
  try {
    const response = (await API.graphql(
      graphqlOperation(listInvestorOnboardings, {
        filter: {
          email: {
            eq: email,
          },
        },
      })
    )) as any;

    return response;
  } catch (error) {
    console.log({ error });
    throw new Error(
      "Could not fetch investor account right now, Try again later"
    );
  }
};
