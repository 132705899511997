import { State } from "../types/Authenticate";

const isAuthenticated =
  localStorage.getItem("is-authenticated") === "true" &&
  localStorage.getItem("has-access-of-dashboard") === "true" &&
  localStorage.getItem("eligibility-check-status") === "true" &&
  localStorage.getItem("eligibility-user-id");

const initialState: State = {
  authenticated: !!isAuthenticated || false,
};

export default initialState;
