import { Action, ActionType, State } from "../types/User";

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.SET_USER_DETAILS:
      return {
        ...state,
        user: action.payload.user,
      };

    case ActionType.REMOVE_USER_DETAILS:
      return {
        ...state,
        user: action.payload.user,
      };

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

export default reducer;
