import { FunctionComponent } from "react";
import { IconProps } from "../../types/IconProps";

const OurLegUp: FunctionComponent<IconProps> = ({
  height = 33,
  width = 166,
  fill = "#ffffff",
  className = "",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_3078_3516)">
        <path
          d="M13.1019 0.140381L0.138672 10.2349V23.2864C0.13977 24.1227 0.467754 24.9245 1.0507 25.5159C1.63365 26.1073 2.42399 26.44 3.24841 26.4412H11.5069V18.6033L13.1019 17.3613L14.6955 18.6033V26.4412H22.954C23.7785 26.4404 24.5691 26.1078 25.1521 25.5163C25.7351 24.9248 26.063 24.1228 26.0637 23.2864V10.2349L13.1019 0.140381ZM13.1019 14.9952L9.65601 17.6827V24.5592H6.75101V14.8759L13.1019 9.93458L19.4542 14.8759V24.5592H16.5492V17.6827L13.1019 14.9952ZM24.2087 23.2864C24.2083 23.6253 24.0754 23.9503 23.8392 24.19C23.6029 24.4297 23.2826 24.5645 22.9485 24.5648H21.3037V13.9553L13.1019 7.56708L4.89734 13.9553V24.5592H3.24841C2.91453 24.5585 2.59455 24.4235 2.3586 24.1839C2.12264 23.9442 1.98994 23.6195 1.98957 23.2807V11.1597L13.1019 2.50648L24.2087 11.1597V23.2864Z"
          fill="white"
        />
        <path
          d="M32.5469 15.7445C32.5469 9.87835 36.2072 5.64014 41.4002 5.64014C46.5932 5.64014 50.3504 9.85028 50.3504 15.8441C50.3504 21.7117 46.7219 25.9555 41.497 25.9555C36.2985 25.9499 32.5469 21.7369 32.5469 15.7445ZM46.5185 16.8672C46.5185 11.0319 44.2111 6.6632 40.9506 6.6632C38.5519 6.6632 36.3981 9.00404 36.3981 14.7144C36.3981 20.5497 38.7055 24.8875 41.9964 24.8875C44.3578 24.8889 46.5116 22.5481 46.5116 16.8686L46.5185 16.8672Z"
          fill="white"
        />
        <path
          d="M62.7903 25.2005L63.3132 22.2983H63.0366C62.7585 23.2961 61.129 25.8222 57.8989 25.8222C55.745 25.8222 53.0697 24.6995 53.0697 20.4234V12.8746C53.0697 11.5639 52.7307 11.0951 51.2852 11.0334V10.2222L55.7768 9.78439C56.2071 9.75351 56.546 9.97244 56.546 10.472V20.3631C56.546 22.6085 57.7149 24.0119 59.498 24.0119C61.5287 24.0119 62.4819 22.1706 62.6977 20.3631V12.8746C62.6977 11.5639 62.3587 11.0951 60.9132 11.0334V10.2222L65.373 9.78439C65.8655 9.72264 66.174 9.97244 66.174 10.472V22.3615C66.174 23.8589 66.6969 24.4834 68.0193 24.7009V25.5121C66.3275 25.5121 64.8197 25.5444 63.435 25.8868C63.281 25.8335 63.1419 25.7433 63.0297 25.6239C62.9175 25.5044 62.8354 25.3591 62.7903 25.2005Z"
          fill="white"
        />
        <path
          d="M69.0982 24.6995C70.1122 24.5746 70.9436 24.1999 70.9436 22.7966V13.4992C70.9436 12.3133 70.2671 11.9695 69.0664 12.1253V11.3141C70.9436 10.9703 72.45 10.378 73.7697 9.53601C73.9265 9.59951 74.068 9.69639 74.1847 9.8201C74.3014 9.94381 74.3906 10.0915 74.4462 10.2531L73.7697 13.7798H73.9842C74.8142 11.0952 76.0453 9.59776 78.1065 9.59776C79.7056 9.59776 80.7819 10.5338 80.7819 11.8432C80.7819 12.8733 80.1054 13.6213 79.1827 13.6213C77.7676 13.6213 77.5227 11.9049 76.3524 11.9049C75.03 11.9049 74.4158 14.09 74.4158 15.9312V22.7966C74.4158 24.1999 75.4007 24.5746 76.5696 24.6995V25.5107C74.016 25.4181 71.4637 25.4181 69.0996 25.5107L69.0982 24.6995Z"
          fill="white"
        />
        <path
          d="M82.6328 24.6995C84.2928 24.5437 84.878 24.1999 84.878 22.8583V8.78655C84.878 7.44491 84.2942 7.10249 82.6328 6.94531V6.13416C85.2777 6.22818 88.108 6.22818 90.753 6.13416V6.94531C89.093 7.10109 88.5078 7.44491 88.5078 8.78655V22.8597C88.5078 24.2013 89.0003 24.5437 90.0461 24.5437H90.9688C93.676 24.5437 94.506 23.8883 96.4744 20.269H97.2131C96.6833 21.9684 96.3731 23.7303 96.2905 25.5106C91.4917 25.418 87.3085 25.418 82.6328 25.5106V24.6995Z"
          fill="white"
        />
        <path
          d="M110.251 22.4542H111.205C110.283 24.6701 107.945 25.856 105.422 25.856C101.424 25.856 98.2852 23.0155 98.2852 17.7739C98.2852 12.7217 101.178 9.59778 105.238 9.59778C108.558 9.59778 111.266 11.5625 111.266 15.1215C111.266 16.4631 110.867 17.4609 109.667 17.4609H102.009C102.193 21.7062 103.701 24.701 106.746 24.701C108.069 24.6996 109.483 24.1382 110.251 22.4542ZM101.979 16.56H107.854C108.13 12.9715 107.085 10.5058 104.932 10.5058C102.84 10.503 101.979 12.8747 101.979 16.56Z"
          fill="white"
        />
        <path
          d="M112.326 28.1027C112.326 26.792 113.126 25.825 114.356 25.825C115.217 25.825 115.956 26.1997 115.956 27.1358C115.956 27.728 115.648 28.1967 115.648 28.8521C115.648 30.2555 117.093 31.1607 119.616 31.1607C122.477 31.1607 124.291 30.0057 124.291 28.0732C124.291 26.6067 123.214 26.1688 120.293 25.6384L116.294 24.9521C113.679 24.4848 112.756 23.3298 112.756 22.0808C112.756 20.6143 113.986 19.5856 115.986 19.6473C114.479 18.992 113.279 17.3696 113.279 15.1565C113.279 12.1603 115.493 9.60193 119.401 9.60193C121.954 9.60193 123.461 10.7246 124.414 11.787C124.446 10.5071 125.244 9.66368 126.382 9.66368C127.274 9.66368 127.89 10.1942 127.89 11.1316C127.89 11.787 127.613 12.4101 126.937 12.4101C126.414 12.4101 126.075 12.0985 125.645 12.0985C125.152 12.0985 125.06 12.535 125.215 12.9406C125.499 13.633 125.645 14.3756 125.645 15.1256C125.645 18.4938 123.155 20.7111 119.558 20.7111C117.898 20.7111 117.16 20.2424 116.328 20.2424C115.56 20.2424 115.252 20.5862 115.252 21.0549C115.252 21.6163 115.837 21.8661 116.76 22.0527L121.527 22.927C125.157 23.5824 127.218 24.5493 127.218 27.0768C127.218 30.2288 123.927 32.0392 119.374 32.0392C115.494 32.035 112.326 30.7242 112.326 28.1027ZM122.169 15.1565C122.169 12.8788 121.554 10.4762 119.461 10.4762C117.368 10.4762 116.753 12.8746 116.753 15.1565C116.753 17.4384 117.399 19.8368 119.459 19.8368C121.554 19.8326 122.169 17.43 122.169 15.1565Z"
          fill="white"
        />
        <path
          d="M149.758 6.94531C148.098 7.10109 147.513 7.44491 147.513 8.56762V22.8597C147.513 24.2013 148.098 24.5437 149.758 24.7009V25.512C147.113 25.4194 145.175 25.4194 143.672 25.512C143.826 24.9816 143.887 24.2013 143.887 23.4842V22.4232C143.114 24.3585 140.96 25.8222 138.131 25.8222C134.717 25.8222 131.856 23.6371 131.856 19.3933V8.56762C131.856 7.44491 131.271 7.10109 129.611 6.94531V6.13416C132.256 6.22818 135.087 6.22818 137.911 6.13416V6.94531C136.098 7.03934 135.482 7.44491 135.482 8.56762V19.2095C135.482 22.8919 137.112 24.5142 139.389 24.5142C142.003 24.5142 143.879 22.2043 143.879 19.2095V8.56762C143.879 7.44491 143.263 7.03934 141.511 6.94531V6.13416C144.277 6.22818 147.109 6.22818 149.754 6.13416L149.758 6.94531Z"
          fill="white"
        />
        <path
          d="M165.862 17.8356C165.862 23.3902 162.787 25.8559 159.311 25.8559C157.465 25.8559 156.018 25.1697 155.161 24.0779V27.2256C155.161 29.1904 155.744 29.5974 157.283 29.6605V30.5025C155.161 30.7832 153.438 31.3137 151.838 32.2189C151.671 32.1615 151.518 32.0675 151.391 31.9436C151.263 31.8197 151.164 31.6688 151.101 31.5017C151.377 31.0639 151.654 30.379 151.654 28.2557V13.4992C151.654 12.3133 151.008 11.9695 149.809 12.1253V11.3141C151.684 10.9698 153.284 10.3771 154.607 9.53601C154.927 9.67163 155.181 9.92922 155.314 10.2531C155.227 10.8948 155.176 11.5409 155.161 12.1884C156.053 10.5043 157.713 9.59776 159.56 9.59776C162.694 9.59776 165.862 12.2193 165.862 17.8356ZM162.109 18.5233C162.109 14.2472 160.726 10.9085 158.265 10.9085C156.204 10.9085 155.158 13.1862 155.158 15.0906V21.2655C155.158 22.9495 156.48 24.7922 158.542 24.7922C160.417 24.795 162.109 23.2344 162.109 18.5247V18.5233Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_3078_3516">
          <rect width="166" height="32.2778" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default OurLegUp;
