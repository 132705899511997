/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createInvestorOnboarding =
  /* GraphQL */ `mutation CreateInvestorOnboarding(
  $input: CreateInvestorOnboardingInput!
  $condition: ModelInvestorOnboardingConditionInput
) {
  createInvestorOnboarding(input: $input, condition: $condition) {
    email
    authId
    firstName
    lastName
    phone
    status
    propertyAddress
    propertyAddressId
    estimatedValue
    mortgageBalance
    loanToValueRatio
    availableEquity
    isEligible
    lineOfCredit
    lineOfCreditValue
    caveat
    caveatValue
    other
    otherValue
    scheduledCall
    scheduledCallUrl
    crmId
    propertyId
    fileInviteUrl
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateInvestorOnboardingMutationVariables,
    APITypes.CreateInvestorOnboardingMutation
  >;
export const updateInvestorOnboarding =
  /* GraphQL */ `mutation UpdateInvestorOnboarding(
  $input: UpdateInvestorOnboardingInput!
  $condition: ModelInvestorOnboardingConditionInput
) {
  updateInvestorOnboarding(input: $input, condition: $condition) {
    email
    authId
    firstName
    lastName
    phone
    status
    propertyAddress
    propertyAddressId
    estimatedValue
    mortgageBalance
    loanToValueRatio
    availableEquity
    isEligible
    lineOfCredit
    lineOfCreditValue
    caveat
    caveatValue
    other
    otherValue
    scheduledCall
    scheduledCallUrl
    crmId
    propertyId
    fileInviteUrl
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
    APITypes.UpdateInvestorOnboardingMutationVariables,
    APITypes.UpdateInvestorOnboardingMutation
  >;
export const deleteInvestorOnboarding =
  /* GraphQL */ `mutation DeleteInvestorOnboarding(
  $input: DeleteInvestorOnboardingInput!
  $condition: ModelInvestorOnboardingConditionInput
) {
  deleteInvestorOnboarding(input: $input, condition: $condition) {
    email
    authId
    firstName
    lastName
    phone
    status
    propertyAddress
    propertyAddressId
    estimatedValue
    mortgageBalance
    loanToValueRatio
    availableEquity
    isEligible
    lineOfCredit
    lineOfCreditValue
    caveat
    caveatValue
    other
    otherValue
    scheduledCall
    scheduledCallUrl
    crmId
    propertyId
    fileInviteUrl
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
    APITypes.DeleteInvestorOnboardingMutationVariables,
    APITypes.DeleteInvestorOnboardingMutation
  >;
export const createInvestorOnboardingProperty =
  /* GraphQL */ `mutation CreateInvestorOnboardingProperty(
  $input: CreateInvestorOnboardingPropertyInput!
  $condition: ModelInvestorOnboardingPropertyConditionInput
) {
  createInvestorOnboardingProperty(input: $input, condition: $condition) {
    email
    authId
    firstName
    lastName
    phone
    status
    propertyAddress
    postcode
    state
    propertyAddressId
    estimatedValue
    mortgageBalance
    loanToValueRatio
    availableEquity
    isEligible
    lineOfCredit
    lineOfCreditValue
    caveat
    caveatValue
    other
    otherValue
    scheduledCall
    scheduledCallUrl
    crmId
    propertyId
    fileInviteUrl
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateInvestorOnboardingPropertyMutationVariables,
    APITypes.CreateInvestorOnboardingPropertyMutation
  >;
export const updateInvestorOnboardingProperty =
  /* GraphQL */ `mutation UpdateInvestorOnboardingProperty(
  $input: UpdateInvestorOnboardingPropertyInput!
  $condition: ModelInvestorOnboardingPropertyConditionInput
) {
  updateInvestorOnboardingProperty(input: $input, condition: $condition) {
    email
    authId
    firstName
    lastName
    phone
    status
    propertyAddress
    postcode
    state
    propertyAddressId
    estimatedValue
    mortgageBalance
    loanToValueRatio
    availableEquity
    isEligible
    lineOfCredit
    lineOfCreditValue
    caveat
    caveatValue
    other
    otherValue
    scheduledCall
    scheduledCallUrl
    crmId
    propertyId
    fileInviteUrl
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
    APITypes.UpdateInvestorOnboardingPropertyMutationVariables,
    APITypes.UpdateInvestorOnboardingPropertyMutation
  >;
export const deleteInvestorOnboardingProperty =
  /* GraphQL */ `mutation DeleteInvestorOnboardingProperty(
  $input: DeleteInvestorOnboardingPropertyInput!
  $condition: ModelInvestorOnboardingPropertyConditionInput
) {
  deleteInvestorOnboardingProperty(input: $input, condition: $condition) {
    email
    authId
    firstName
    lastName
    phone
    status
    propertyAddress
    postcode
    state
    propertyAddressId
    estimatedValue
    mortgageBalance
    loanToValueRatio
    availableEquity
    isEligible
    lineOfCredit
    lineOfCreditValue
    caveat
    caveatValue
    other
    otherValue
    scheduledCall
    scheduledCallUrl
    crmId
    propertyId
    fileInviteUrl
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
    APITypes.DeleteInvestorOnboardingPropertyMutationVariables,
    APITypes.DeleteInvestorOnboardingPropertyMutation
  >;
export const createInvestorReturns =
  /* GraphQL */ `mutation CreateInvestorReturns(
  $input: CreateInvestorReturnsInput!
  $condition: ModelInvestorReturnsConditionInput
) {
  createInvestorReturns(input: $input, condition: $condition) {
    id
    email
    authId
    estimatedAnnualReturn
    estimatedNextQuarterReturn
    committedEquity
    committedEquityPercent
    uncommittedEquity
    rateOfReturn {
      month
      return
      __typename
    }
    arrearsRate {
      month
      return
      __typename
    }
    fundsAvailable
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateInvestorReturnsMutationVariables,
    APITypes.CreateInvestorReturnsMutation
  >;
export const updateInvestorReturns =
  /* GraphQL */ `mutation UpdateInvestorReturns(
  $input: UpdateInvestorReturnsInput!
  $condition: ModelInvestorReturnsConditionInput
) {
  updateInvestorReturns(input: $input, condition: $condition) {
    id
    email
    authId
    estimatedAnnualReturn
    estimatedNextQuarterReturn
    committedEquity
    committedEquityPercent
    uncommittedEquity
    rateOfReturn {
      month
      return
      __typename
    }
    arrearsRate {
      month
      return
      __typename
    }
    fundsAvailable
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
    APITypes.UpdateInvestorReturnsMutationVariables,
    APITypes.UpdateInvestorReturnsMutation
  >;
export const deleteInvestorReturns =
  /* GraphQL */ `mutation DeleteInvestorReturns(
  $input: DeleteInvestorReturnsInput!
  $condition: ModelInvestorReturnsConditionInput
) {
  deleteInvestorReturns(input: $input, condition: $condition) {
    id
    email
    authId
    estimatedAnnualReturn
    estimatedNextQuarterReturn
    committedEquity
    committedEquityPercent
    uncommittedEquity
    rateOfReturn {
      month
      return
      __typename
    }
    arrearsRate {
      month
      return
      __typename
    }
    fundsAvailable
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
    APITypes.DeleteInvestorReturnsMutationVariables,
    APITypes.DeleteInvestorReturnsMutation
  >;
export const createInvestorReturnsData =
  /* GraphQL */ `mutation CreateInvestorReturnsData(
  $input: CreateInvestorReturnsDataInput!
  $condition: ModelInvestorReturnsDataConditionInput
) {
  createInvestorReturnsData(input: $input, condition: $condition) {
    email
    propertyAddress
    propertyAddressId
    transactionData {
      date
      description
      amount
      transactionType
      __typename
    }
    dailyData {
      dayEnding
      estPropertyValue
      committed
      uncommitted
      available
      expectedArrears
      arrears
      expectedReturn
      return
      est90dayArrears
      estAnnualIncome
      estReturnRate
      __typename
    }
    weeklyData {
      weekEnding
      estPropertyValue
      committed
      uncommitted
      available
      expectedArrears
      arrears
      expectedReturn
      return
      est90dayArrears
      estAnnualIncome
      estReturnRate
      __typename
    }
    monthlyData {
      monthEnding
      estPropertyValue
      committed
      uncommitted
      available
      expectedArrears
      arrears
      expectedReturn
      return
      est90dayArrears
      estAnnualIncome
      estReturnRate
      __typename
    }
    quarterlyData {
      quarterEnding
      historicalReturn
      expectedReturn
      __typename
    }
    monthlyComparisonData {
      monthEnding
      housingArrearsRate
      usBondRate
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateInvestorReturnsDataMutationVariables,
    APITypes.CreateInvestorReturnsDataMutation
  >;
export const updateInvestorReturnsData =
  /* GraphQL */ `mutation UpdateInvestorReturnsData(
  $input: UpdateInvestorReturnsDataInput!
  $condition: ModelInvestorReturnsDataConditionInput
) {
  updateInvestorReturnsData(input: $input, condition: $condition) {
    email
    propertyAddress
    propertyAddressId
    transactionData {
      date
      description
      amount
      transactionType
      __typename
    }
    dailyData {
      dayEnding
      estPropertyValue
      committed
      uncommitted
      available
      expectedArrears
      arrears
      expectedReturn
      return
      est90dayArrears
      estAnnualIncome
      estReturnRate
      __typename
    }
    weeklyData {
      weekEnding
      estPropertyValue
      committed
      uncommitted
      available
      expectedArrears
      arrears
      expectedReturn
      return
      est90dayArrears
      estAnnualIncome
      estReturnRate
      __typename
    }
    monthlyData {
      monthEnding
      estPropertyValue
      committed
      uncommitted
      available
      expectedArrears
      arrears
      expectedReturn
      return
      est90dayArrears
      estAnnualIncome
      estReturnRate
      __typename
    }
    quarterlyData {
      quarterEnding
      historicalReturn
      expectedReturn
      __typename
    }
    monthlyComparisonData {
      monthEnding
      housingArrearsRate
      usBondRate
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
    APITypes.UpdateInvestorReturnsDataMutationVariables,
    APITypes.UpdateInvestorReturnsDataMutation
  >;
export const deleteInvestorReturnsData =
  /* GraphQL */ `mutation DeleteInvestorReturnsData(
  $input: DeleteInvestorReturnsDataInput!
  $condition: ModelInvestorReturnsDataConditionInput
) {
  deleteInvestorReturnsData(input: $input, condition: $condition) {
    email
    propertyAddress
    propertyAddressId
    transactionData {
      date
      description
      amount
      transactionType
      __typename
    }
    dailyData {
      dayEnding
      estPropertyValue
      committed
      uncommitted
      available
      expectedArrears
      arrears
      expectedReturn
      return
      est90dayArrears
      estAnnualIncome
      estReturnRate
      __typename
    }
    weeklyData {
      weekEnding
      estPropertyValue
      committed
      uncommitted
      available
      expectedArrears
      arrears
      expectedReturn
      return
      est90dayArrears
      estAnnualIncome
      estReturnRate
      __typename
    }
    monthlyData {
      monthEnding
      estPropertyValue
      committed
      uncommitted
      available
      expectedArrears
      arrears
      expectedReturn
      return
      est90dayArrears
      estAnnualIncome
      estReturnRate
      __typename
    }
    quarterlyData {
      quarterEnding
      historicalReturn
      expectedReturn
      __typename
    }
    monthlyComparisonData {
      monthEnding
      housingArrearsRate
      usBondRate
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
    APITypes.DeleteInvestorReturnsDataMutationVariables,
    APITypes.DeleteInvestorReturnsDataMutation
  >;
export const createInvestorAccount =
  /* GraphQL */ `mutation CreateInvestorAccount(
  $input: CreateInvestorAccountInput!
  $condition: ModelInvestorAccountConditionInput
) {
  createInvestorAccount(input: $input, condition: $condition) {
    id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateInvestorAccountMutationVariables,
    APITypes.CreateInvestorAccountMutation
  >;
export const updateInvestorAccount =
  /* GraphQL */ `mutation UpdateInvestorAccount(
  $input: UpdateInvestorAccountInput!
  $condition: ModelInvestorAccountConditionInput
) {
  updateInvestorAccount(input: $input, condition: $condition) {
    id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
    APITypes.UpdateInvestorAccountMutationVariables,
    APITypes.UpdateInvestorAccountMutation
  >;
export const deleteInvestorAccount =
  /* GraphQL */ `mutation DeleteInvestorAccount(
  $input: DeleteInvestorAccountInput!
  $condition: ModelInvestorAccountConditionInput
) {
  deleteInvestorAccount(input: $input, condition: $condition) {
    id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
    APITypes.DeleteInvestorAccountMutationVariables,
    APITypes.DeleteInvestorAccountMutation
  >;
export const createInvestorOnboardingDataSet =
  /* GraphQL */ `mutation CreateInvestorOnboardingDataSet(
  $input: CreateInvestorOnboardingDataSetInput!
  $condition: ModelInvestorOnboardingDataSetConditionInput
) {
  createInvestorOnboardingDataSet(input: $input, condition: $condition) {
    email
    owner
    propertyAddress
    propertyAddressId
    dateRange
    estimatedValue
    committed
    uncommitted
    investedAmount
    availableIncrement
    oluArrears
    returnRate
    quarterlyReturns {
      date
      amount
      __typename
    }
    estimatedAnnualIncome
    estimatedReturnRate
    estimated90DaysArrears
    combinedData {
      date
      committed
      uncommitted
      estimatedValue
      investedAmount
      availableIncrement
      oluArrears
      returnRate
      housingArrears
      usBond
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateInvestorOnboardingDataSetMutationVariables,
    APITypes.CreateInvestorOnboardingDataSetMutation
  >;
export const updateInvestorOnboardingDataSet =
  /* GraphQL */ `mutation UpdateInvestorOnboardingDataSet(
  $input: UpdateInvestorOnboardingDataSetInput!
  $condition: ModelInvestorOnboardingDataSetConditionInput
) {
  updateInvestorOnboardingDataSet(input: $input, condition: $condition) {
    email
    owner
    propertyAddress
    propertyAddressId
    dateRange
    estimatedValue
    committed
    uncommitted
    investedAmount
    availableIncrement
    oluArrears
    returnRate
    quarterlyReturns {
      date
      amount
      __typename
    }
    estimatedAnnualIncome
    estimatedReturnRate
    estimated90DaysArrears
    combinedData {
      date
      committed
      uncommitted
      estimatedValue
      investedAmount
      availableIncrement
      oluArrears
      returnRate
      housingArrears
      usBond
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.UpdateInvestorOnboardingDataSetMutationVariables,
    APITypes.UpdateInvestorOnboardingDataSetMutation
  >;
export const deleteInvestorOnboardingDataSet =
  /* GraphQL */ `mutation DeleteInvestorOnboardingDataSet(
  $input: DeleteInvestorOnboardingDataSetInput!
  $condition: ModelInvestorOnboardingDataSetConditionInput
) {
  deleteInvestorOnboardingDataSet(input: $input, condition: $condition) {
    email
    owner
    propertyAddress
    propertyAddressId
    dateRange
    estimatedValue
    committed
    uncommitted
    investedAmount
    availableIncrement
    oluArrears
    returnRate
    quarterlyReturns {
      date
      amount
      __typename
    }
    estimatedAnnualIncome
    estimatedReturnRate
    estimated90DaysArrears
    combinedData {
      date
      committed
      uncommitted
      estimatedValue
      investedAmount
      availableIncrement
      oluArrears
      returnRate
      housingArrears
      usBond
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.DeleteInvestorOnboardingDataSetMutationVariables,
    APITypes.DeleteInvestorOnboardingDataSetMutation
  >;
export const createInvestorOnboardingUsBondDataSet =
  /* GraphQL */ `mutation CreateInvestorOnboardingUsBondDataSet(
  $input: CreateInvestorOnboardingUsBondDataSetInput!
  $condition: ModelInvestorOnboardingUsBondDataSetConditionInput
) {
  createInvestorOnboardingUsBondDataSet(input: $input, condition: $condition) {
    date
    usBond
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateInvestorOnboardingUsBondDataSetMutationVariables,
    APITypes.CreateInvestorOnboardingUsBondDataSetMutation
  >;
export const updateInvestorOnboardingUsBondDataSet =
  /* GraphQL */ `mutation UpdateInvestorOnboardingUsBondDataSet(
  $input: UpdateInvestorOnboardingUsBondDataSetInput!
  $condition: ModelInvestorOnboardingUsBondDataSetConditionInput
) {
  updateInvestorOnboardingUsBondDataSet(input: $input, condition: $condition) {
    date
    usBond
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
    APITypes.UpdateInvestorOnboardingUsBondDataSetMutationVariables,
    APITypes.UpdateInvestorOnboardingUsBondDataSetMutation
  >;
export const deleteInvestorOnboardingUsBondDataSet =
  /* GraphQL */ `mutation DeleteInvestorOnboardingUsBondDataSet(
  $input: DeleteInvestorOnboardingUsBondDataSetInput!
  $condition: ModelInvestorOnboardingUsBondDataSetConditionInput
) {
  deleteInvestorOnboardingUsBondDataSet(input: $input, condition: $condition) {
    date
    usBond
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
    APITypes.DeleteInvestorOnboardingUsBondDataSetMutationVariables,
    APITypes.DeleteInvestorOnboardingUsBondDataSetMutation
  >;
export const createInvestorOnboardingHousingArrearsDataSet =
  /* GraphQL */ `mutation CreateInvestorOnboardingHousingArrearsDataSet(
  $input: CreateInvestorOnboardingHousingArrearsDataSetInput!
  $condition: ModelInvestorOnboardingHousingArrearsDataSetConditionInput
) {
  createInvestorOnboardingHousingArrearsDataSet(
    input: $input
    condition: $condition
  ) {
    date
    housingArrears
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateInvestorOnboardingHousingArrearsDataSetMutationVariables,
    APITypes.CreateInvestorOnboardingHousingArrearsDataSetMutation
  >;
export const updateInvestorOnboardingHousingArrearsDataSet =
  /* GraphQL */ `mutation UpdateInvestorOnboardingHousingArrearsDataSet(
  $input: UpdateInvestorOnboardingHousingArrearsDataSetInput!
  $condition: ModelInvestorOnboardingHousingArrearsDataSetConditionInput
) {
  updateInvestorOnboardingHousingArrearsDataSet(
    input: $input
    condition: $condition
  ) {
    date
    housingArrears
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
    APITypes.UpdateInvestorOnboardingHousingArrearsDataSetMutationVariables,
    APITypes.UpdateInvestorOnboardingHousingArrearsDataSetMutation
  >;
export const deleteInvestorOnboardingHousingArrearsDataSet =
  /* GraphQL */ `mutation DeleteInvestorOnboardingHousingArrearsDataSet(
  $input: DeleteInvestorOnboardingHousingArrearsDataSetInput!
  $condition: ModelInvestorOnboardingHousingArrearsDataSetConditionInput
) {
  deleteInvestorOnboardingHousingArrearsDataSet(
    input: $input
    condition: $condition
  ) {
    date
    housingArrears
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
    APITypes.DeleteInvestorOnboardingHousingArrearsDataSetMutationVariables,
    APITypes.DeleteInvestorOnboardingHousingArrearsDataSetMutation
  >;
export const createInvestorProfile =
  /* GraphQL */ `mutation CreateInvestorProfile(
  $input: CreateInvestorProfileInput!
  $condition: ModelInvestorProfileConditionInput
) {
  createInvestorProfile(input: $input, condition: $condition) {
    email
    owner
    phone
    contactId
    contactType
    secondaryEmail
    firstName
    lastName
    homePhone
    dateOfBirth
    suburb
    street
    postcode
    city
    state
    country
    address1
    address2
    isTwoFactorEnabled
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateInvestorProfileMutationVariables,
    APITypes.CreateInvestorProfileMutation
  >;
export const updateInvestorProfile =
  /* GraphQL */ `mutation UpdateInvestorProfile(
  $input: UpdateInvestorProfileInput!
  $condition: ModelInvestorProfileConditionInput
) {
  updateInvestorProfile(input: $input, condition: $condition) {
    email
    owner
    phone
    contactId
    contactType
    secondaryEmail
    firstName
    lastName
    homePhone
    dateOfBirth
    suburb
    street
    postcode
    city
    state
    country
    address1
    address2
    isTwoFactorEnabled
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.UpdateInvestorProfileMutationVariables,
    APITypes.UpdateInvestorProfileMutation
  >;
export const deleteInvestorProfile =
  /* GraphQL */ `mutation DeleteInvestorProfile(
  $input: DeleteInvestorProfileInput!
  $condition: ModelInvestorProfileConditionInput
) {
  deleteInvestorProfile(input: $input, condition: $condition) {
    email
    owner
    phone
    contactId
    contactType
    secondaryEmail
    firstName
    lastName
    homePhone
    dateOfBirth
    suburb
    street
    postcode
    city
    state
    country
    address1
    address2
    isTwoFactorEnabled
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.DeleteInvestorProfileMutationVariables,
    APITypes.DeleteInvestorProfileMutation
  >;
export const createTransactionsData =
  /* GraphQL */ `mutation CreateTransactionsData(
  $input: CreateTransactionsDataInput!
  $condition: ModelTransactionsDataConditionInput
) {
  createTransactionsData(input: $input, condition: $condition) {
    email
    owner
    dateRange
    credits
    debits
    balance
    descriptions
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateTransactionsDataMutationVariables,
    APITypes.CreateTransactionsDataMutation
  >;
export const updateTransactionsData =
  /* GraphQL */ `mutation UpdateTransactionsData(
  $input: UpdateTransactionsDataInput!
  $condition: ModelTransactionsDataConditionInput
) {
  updateTransactionsData(input: $input, condition: $condition) {
    email
    owner
    dateRange
    credits
    debits
    balance
    descriptions
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.UpdateTransactionsDataMutationVariables,
    APITypes.UpdateTransactionsDataMutation
  >;
export const deleteTransactionsData =
  /* GraphQL */ `mutation DeleteTransactionsData(
  $input: DeleteTransactionsDataInput!
  $condition: ModelTransactionsDataConditionInput
) {
  deleteTransactionsData(input: $input, condition: $condition) {
    email
    owner
    dateRange
    credits
    debits
    balance
    descriptions
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.DeleteTransactionsDataMutationVariables,
    APITypes.DeleteTransactionsDataMutation
  >;
export const createPerformanceData =
  /* GraphQL */ `mutation CreatePerformanceData(
  $input: CreatePerformanceDataInput!
  $condition: ModelPerformanceDataConditionInput
) {
  createPerformanceData(input: $input, condition: $condition) {
    email
    owner
    dates
    expectedReturns
    actualReturns
    generalHousingMarketArrears
    ourlegup90DayArrearsRatePa
    returnRatesPerAnnum
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreatePerformanceDataMutationVariables,
    APITypes.CreatePerformanceDataMutation
  >;
export const updatePerformanceData =
  /* GraphQL */ `mutation UpdatePerformanceData(
  $input: UpdatePerformanceDataInput!
  $condition: ModelPerformanceDataConditionInput
) {
  updatePerformanceData(input: $input, condition: $condition) {
    email
    owner
    dates
    expectedReturns
    actualReturns
    generalHousingMarketArrears
    ourlegup90DayArrearsRatePa
    returnRatesPerAnnum
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.UpdatePerformanceDataMutationVariables,
    APITypes.UpdatePerformanceDataMutation
  >;
export const deletePerformanceData =
  /* GraphQL */ `mutation DeletePerformanceData(
  $input: DeletePerformanceDataInput!
  $condition: ModelPerformanceDataConditionInput
) {
  deletePerformanceData(input: $input, condition: $condition) {
    email
    owner
    dates
    expectedReturns
    actualReturns
    generalHousingMarketArrears
    ourlegup90DayArrearsRatePa
    returnRatesPerAnnum
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.DeletePerformanceDataMutationVariables,
    APITypes.DeletePerformanceDataMutation
  >;
export const createNotificationsData =
  /* GraphQL */ `mutation CreateNotificationsData(
  $input: CreateNotificationsDataInput!
  $condition: ModelNotificationsDataConditionInput
) {
  createNotificationsData(input: $input, condition: $condition) {
    email
    owner
    dates
    times
    messages
    status
    redirection
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateNotificationsDataMutationVariables,
    APITypes.CreateNotificationsDataMutation
  >;
export const updateNotificationsData =
  /* GraphQL */ `mutation UpdateNotificationsData(
  $input: UpdateNotificationsDataInput!
  $condition: ModelNotificationsDataConditionInput
) {
  updateNotificationsData(input: $input, condition: $condition) {
    email
    owner
    dates
    times
    messages
    status
    redirection
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.UpdateNotificationsDataMutationVariables,
    APITypes.UpdateNotificationsDataMutation
  >;
export const deleteNotificationsData =
  /* GraphQL */ `mutation DeleteNotificationsData(
  $input: DeleteNotificationsDataInput!
  $condition: ModelNotificationsDataConditionInput
) {
  deleteNotificationsData(input: $input, condition: $condition) {
    email
    owner
    dates
    times
    messages
    status
    redirection
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.DeleteNotificationsDataMutationVariables,
    APITypes.DeleteNotificationsDataMutation
  >;
