import { Box } from "@mui/material";

import OurLegUp from "../../assets/svgs/OurLegUp-rgb";
import BrandLogoLink from "../../components/atoms/BrandLogoLink";
import SidebarNavigations from "../../components/molecules/SidebarNavigations";
import { drawerWidth, headerHeight } from "../../constants/layout";
import { navItems } from "../../constants/sidebar-menu-items";

const NavigationLinks = () => {
  return (
    <Box
      sx={{
        minHeight: "100vh",
        background: (theme) => theme.palette.primary.main,
      }}
    >
      <Box
        sx={{
          py: 1,
          height: `${headerHeight + 10}px`,
          width: `${drawerWidth}px`,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: (theme) => theme.palette.primary.main,
        }}
      >
        <BrandLogoLink>
          <OurLegUp fill="#fff" />
        </BrandLogoLink>
      </Box>

      <SidebarNavigations items={navItems} />
    </Box>
  );
};

export default NavigationLinks;
