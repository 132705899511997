import { useState } from "react";

const useMockDate = () => {
  const [mockDate, setMockDate] = useState<Date | string | null>(() =>
    localStorage.getItem("mock-date")
      ? (new Date(JSON.parse(localStorage.getItem("mock-date") || "")) as Date)
      : new Date()
  );

  const mockToday = mockDate as Date;
  const currentMockTime = mockToday.getTime();
  const currentMockDay = mockToday.getDay();
  const currentMockMonth = mockToday.getMonth();
  const currentMockYear = mockToday.getFullYear();

  return {
    mockDate,
    mockToday,
    setMockDate,
    currentMockTime,
    currentMockDay,
    currentMockMonth,
    currentMockYear,
  };
};

export default useMockDate;
