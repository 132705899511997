import {
  AccountBalance,
  Help,
  Person,
  // Security,
  // eslint-disable-next-line prettier/prettier
  Settings,
} from "@mui/icons-material";

export const profileMenuItems = [
  {
    id: "deb346bb-db95-4ae1-b130-28d058686101",
    name: "Profile",
    path: "/profile",
    icon: Person,
  },
  {
    id: "deb346bb-db95-4ae1-b130-28d058686100",
    name: "Accounts",
    path: "/accounts",
    icon: AccountBalance,
    divider: true,
  },
  // {
  //   id: "2f94c71f-c79f-426b-be7c-cfbcd41d14352",
  //   name: "Security",
  //   path: "/security",
  //   icon: Security,
  // },
  {
    id: "2f94c71f-c79f-426b-be7c-cfbcd41d1350",
    name: "More",
    path: "/more",
    icon: Settings,
  },
  {
    id: "2f94c71f-c79f-426b-be7c-cfbcd41d1351",
    name: "Get Help",
    path: "https://support.ourlegup.com/portal/en/newticket",
    icon: Help,
    divider: true,
    openInNewTab: true,
  },

  // {
  //   id: "eff3b49c-2da4-11ed-a261-0242ac120002",
  //   name: "Profile",
  //   path: "/profile",
  //   icon: Person,
  // },
  // {
  //   id: "eff3b49c-2da4-11ed-a261-0242ac120003",
  //   name: "My Account",
  //   path: "/account",
  //   icon: ManageAccounts,
  // },
  // {
  //   id: "eff3b49c-2da4-11ed-a261-0242ac120004",
  //   name: "Add another account",
  //   path: "/add-new-account",
  //   icon: PersonAdd,
  // },
  // {
  //   id: "eff3b49c-2da4-11ed-a261-0242ac120005",
  //   name: "Settings",
  //   path: "/settings",
  //   icon: Settings,
  // },
  // {
  //   id: "eff3b49c-2da4-11ed-a261-0242ac120006",
  //   name: "Logout",
  //   path: "/",
  //   icon: Logout,
  // },
];
