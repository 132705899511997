import { Dispatch } from "react";

export interface State {
  authenticated: boolean;
}

export interface Action {
  type: string;
  payload: State;
}

export interface Context {
  state: State;
  dispatch: Dispatch<any>;
}

export enum ActionType {
  SET_IS_AUTHENTICATE = "SET_IS_AUTHENTICATE",
  REMOVE_IS_AUTHENTICATE = "REMOVE_IS_AUTHENTICATE",
}
