import { createContext } from "react";

import initialState from "../states/drawerState";
import { Context } from "../types/Drawer";

const contextInitialState: Context = {
  state: initialState,
  dispatch: () => {},
};

const DrawerContext = createContext<Context>(contextInitialState);

export default DrawerContext;
