import { createContext } from "react";

import initialState from "../states/authenticateState";
import { Context } from "../types/Authenticate";

const contextInitialState: Context = {
  state: initialState,
  dispatch: () => {},
};

const AuthenticateContext = createContext<Context>(contextInitialState);

export default AuthenticateContext;
