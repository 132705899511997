import { createContext } from "react";

import initialState from "../states/messageState";
import { Context } from "../types/Message";

const contextInitialState: Context = {
  state: initialState,
  dispatch: () => {},
};

const MessageContext = createContext<Context>(contextInitialState);

export default MessageContext;
