import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import isEmpty from "../utils/isEmpty";

const useUserDetails = () => {
  const [userDetails, setUserDetails] = useState(() =>
    JSON.parse(localStorage.getItem("user-details") || "{}")
  );
  const [userId, setUserId] = useState(() => localStorage.getItem("userSub"));

  const navigateTo = useNavigate();

  useEffect(() => {
    if (isEmpty(userDetails || userId)) {
      Auth.currentUserInfo()
        .then((response) => {
          if (response && response?.attributes) {
            const user = response?.attributes;

            setUserDetails(user);
            setUserId(user?.sub);

            localStorage.setItem("user-details", JSON.stringify(user));
            localStorage.setItem("userSub", JSON.stringify(user));
          }
        })
        // eslint-disable-next-line @typescript-eslint/no-shadow
        .catch((error) => {
          console.log({ error });
          navigateTo("/email-signin");
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { userDetails, userId };
};

export default useUserDetails;
